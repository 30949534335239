.section-pages-top {
    .ads-block {
        @media ($mobile) {
            text-align: center;
            margin-bottom: 30px;
        }
    }
    .list-items-section .item .large-block-horizontal {
        margin-bottom: 20px;
        @media ($mobile) {
            margin-bottom: 20px;
        }
    }
    .large-block-vertical {
        margin-bottom: 30px;
        @media ($mobile) {
            margin-bottom: 30px;
        }
        .image-large {
            position: relative;
            height: 535px;
            margin-bottom: 23px;
            @media (min-width: 768px) and (max-width: 1024px) {
                height: auto;
                margin-bottom: 5px;
            }
            @media (max-width:767px) {
                height: auto;
                margin-bottom: 5px;
            }
        }
        .details {
            h2 {
                margin-bottom: 15px;
                @media ($mobile) {
                    margin-bottom: 6px;
                }
                a {
                    font-size: 32px;
                    line-height: 1.38;
                    font-weight: bold;
                    color: $gray1;
                    @media ($mobile) {
                        font-size: 18px;
                        line-height: 1.78;
                    }
                }
            }
            .author-and-section {
                margin-bottom: 6px;
                @media ($mobile) {
                    margin-bottom: 4px;
                }
                .opinion-article {
                    a {
                        display: block;
                        font-size: 14px;
                        font-weight: bold;
                        line-height: 1.14;
                        color: #0f52aa;
                    }
                }
                >div {
                    &:not(:first-child) {
                        padding-right: 10px;
                        margin-right: 10px;
                        border-right: 1px solid $gray4;
                    }
                }
            }
            .author {
                margin-bottom: 0;
                .date {
                    font-size: 14px;
                    color: $gray4;
                    @media ($mobile) {
                        line-height: 2.29;
                    }
                }
            }
        }
    }
    .list-items-section .item .large-block-horizontal .left-side .details .author {
        @media ($mobile) {
            margin-bottom: 13px;
        }
    }
    .right-caricatures {
        margin-top: 76px;
        @media ($mobile) {
            margin-top: 29px;
        }
        .title {
            margin-bottom: 32px;
            @media ($mobile) {
                margin-bottom: 21px;
            }
        }
    }
    .others-articles {
        margin-top: 10px;
        margin-bottom: 0;
        @media ($mobile) {
            margin-top: 30px;
            margin-bottom: 0;
        }
        .large-block-vertical {
            @media ($mobile) {
                margin-bottom: 19px !important;
            }
        }
        .title {
            margin-bottom: 32px;
            @media ($mobile) {
                margin-bottom: 21px;
            }
        }
        .large-block-vertical {
            margin-bottom: 30px;
            @media ($mobile) {
                margin-bottom: 0;
            }
            .image-large {
                position: relative;
                height: 260px;
                margin-bottom: 15px;
                @media (min-width: 768px) and (max-width: 1024px) {
                    height: auto;
                    margin-bottom: 5px;
                }
                @media (max-width:767px) {
                    height: auto;
                    margin-bottom: 5px;
                }
            }
            .details {
                h2 {
                    margin-bottom: 2px;
                    @media ($mobile) {
                        margin-top: 5px;
                        margin-bottom: 5px;
                    }
                    a {
                        display: block;
                        height: 56px;
                        overflow: hidden;
                        font-size: 20px;
                        line-height: 1.4;
                        @media ($mobile) {
                            font-size: 18px;
                            line-height: 1.78;
                            height: auto;
                        }
                    }
                }
                .author-and-section {
                    margin-bottom: 5px;
                    @media ($mobile) {
                        margin-bottom: 15px;
                    }
                    .opinion-article {
                        a {
                            display: block;
                            font-size: 14px;
                            font-weight: bold;
                            line-height: 1.14;
                            color: #0f52aa;
                        }
                    }
                    >div {
                        &:not(:first-child) {
                            padding-right: 10px;
                            margin-right: 10px;
                            border-right: 1px solid $gray4;
                        }
                    }
                }
                .author {
                    .date {
                        font-size: 14px;
                        line-height: normal;
                    }
                }
            }
        }
    }
}

.section-side-bar {
    .book-articles-top,
    .book-articles-top2,
    .book-articles-top3 {
        margin-top: -10px;
        @media ($mobile) {
            margin-top: 30px;
        }
        &.book-articles-top2 {
            .block-writer {
                .block-content {
                    .left-side {
                        padding-right: 0;
                        @media ($mobile) {
                            padding-right: 0;
                        }
                        .writer-name {
                            .date {
                                border-right: 0;
                                padding-right: 0;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
        &.book-articles-top3 {
            .block-writer {
                .block-content {
                    .left-side {
                        padding-right: 0;
                        @media ($mobile) {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
        li {
            &:last-child {
                margin-bottom: 30px;
            }
        }
        .block-writer {
            padding-top: 20px;
            border-bottom: dashed 1px $gray5;
            padding-bottom: 18px;
            .block-content {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                .right-side {
                    width: 80px;
                    height: 80px;
                    flex: 0 0 80px;
                    max-width: 80px;
                    @media ($mobile) {
                        width: 70px;
                        height: 70px;
                        flex: 0 0 70px;
                        max-width: 70px;
                    }
                    img {
                        border-radius: 50%;
                    }
                }
                .left-side {
                    padding-right: 19px;
                    @media ($mobile) {
                        padding-right: 10px;
                    }
                    .text {
                        display: block;
                        font-size: 20px;
                        font-weight: bold;
                        line-height: 1.5;
                        color: $gray1;
                        margin-bottom: 11px;
                        @media ($mobile) {
                            margin-bottom: 10px;
                            font-size: 14px;
                            line-height: 1.43;
                        }
                    }
                    .writer-name {
                        a {
                            font-size: 12px;
                            font-weight: bold;
                            line-height: 1.67;
                            color: $blue2;
                            @media (max-width: 300px) {
                                font-size: 10px;
                            }
                        }
                        .date {
                            font-size: 12px;
                            font-weight: bold;
                            line-height: 1.67;
                            color: $blue2;
                            padding-right: 10px;
                            margin-right: 10px;
                            border-right: 1px solid $blue2;
                            @media (max-width: 300px) {
                                font-size: 10px;
                                padding-right: 5px;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
            .description {
                font-size: 16px;
                line-height: 1.75;
                color: $gray3;
                @media ($mobile) {
                    font-size: 14px;
                    line-height: 1.71;
                }
            }
        }
    }
    .book-articles-bottom {
        .block-withot-writer {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 30px;
            }
            .large-block-vertical {
                margin-top: 0;
                margin-bottom: 0;
                .image-large {
                    position: relative;
                    height: 260px;
                    margin-bottom: 15px;
                    @media (min-width: 768px) and (max-width: 1024px) {
                        height: auto;
                    }
                    @media (max-width:767px) {
                        height: auto;
                    }
                }
                .details {
                    h2 {
                        margin-bottom: 2px;
                        @media ($mobile) {
                            margin-top: 5px;
                            margin-bottom: 5px;
                        }
                        a {
                            font-size: 20px;
                            line-height: 1.4;
                            @media ($mobile) {
                                font-size: 18px;
                                line-height: 1.78;
                            }
                        }
                    }
                    .author-and-section {
                        margin-bottom: 5px;
                        @media ($mobile) {
                            margin-bottom: 15px;
                        }
                        .opinion-article {
                            a {
                                display: block;
                                font-size: 14px;
                                font-weight: bold;
                                line-height: 1.14;
                                color: #0f52aa;
                            }
                        }
                        >div {
                            &:not(:first-child) {
                                padding-right: 10px;
                                margin-right: 10px;
                                border-right: 1px solid $gray4;
                            }
                        }
                    }
                    .author {
                        .date {
                            font-size: 14px;
                            line-height: normal;
                        }
                    }
                    .description {
                        max-height: 57px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        @media ($mobile) {
                            max-height: 45px;
                        }
                    }
                }
            }
        }
    }
    .social-media {
        margin-bottom: 30px;
        margin-top: 30px;
    }
    .subscribe-sction {
        margin-bottom: 30px;
    }
}