.title-and-filter-opinion {
    margin-bottom: 40px;
    @media ($mobile) {
        margin-bottom: 30px;
    }
    .opinion-title {
        font-size: 40px;
        font-weight: bold;
        line-height: 0.75;
        color: $gray1;
        @media ($mobile) {
            font-size: 24px;
            line-height: 1.5;
            margin-bottom: 20px;
        }
    }
}

.opinion-page {
    .pagination {
        margin-top: 10px;
    }
    .block-writer-right {
        padding: 20px;
        border-radius: 5px;
        border: 1px solid $gray5;
        margin-bottom: 30px;
        @media ($mobile) {
            margin-bottom: 20px;
        }
        .block-content {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            @media (max-width: 300px) {
                flex-wrap: wrap;
                justify-content: center;
            }
            .right-side {
                a,
                span {
                    display: block;
                    width: 56px;
                    height: 56px;
                    flex: 0 0 56px;
                    max-width: 56px;
                    @media ($mobile) {
                        width: 70px;
                        height: 70px;
                        flex: 0 0 70px;
                        max-width: 70px;
                    }
                    @media (max-width: 300px) {
                        margin-bottom: 10px;
                    }
                }
                img {
                    border-radius: 50%;
                }
            }
            .left-side {
                padding-right: 15px;
                @media ($mobile) {
                    padding-right: 10px;
                }
                @media (max-width: 300px) {
                    display: flex;
                    justify-content: center;
                }
                .writer-name {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.25;
                    color: $blue2;
                    display: block;
                    margin-bottom: 8px;
                }
                .date {
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 1.67;
                    color: $gray4;
                }
            }
        }
        .text {
            display: block;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.5;
            color: $gray1;
            margin-bottom: 15px;
            @media ($mobile) {
                margin-bottom: 20px;
            }
            @media (max-width: 300px) {
                text-align: center;
            }
        }
        .image-opinion {
            img {
                border-radius: 5px;
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }
    }
}

.load-more-article {
    width: 460px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue2;
    border-radius: 5px;
    color: $white;
    margin: 0 auto;
    font-size: 20px;
    font-weight: bold;
    &:hover {
        color: $white;
    }
    @media ($mobile) {
        width: 100%;
        height: 50px;
    }
}

.opinion-article {
    .load-more-article {
        margin-top: 10px;
        margin-bottom: 40px;
        @media ($mobile) {
            margin-bottom: 30px;
        }
    }
}

.opinion-articles-list {
    .navigation {
        margin-top: 10px;
        margin-bottom: 60px;
        @media ($mobile) {
            margin-top: 20px;
        }
    }
}

.authors-article {
    .authors-title {
        font-size: 40px;
        font-weight: bold;
        line-height: 0.75;
        color: $gray1;
        margin-bottom: 40px;
        @media ($mobile) {
            font-size: 24px;
            line-height: 1.5;
            margin-bottom: 20px;
        }
    }
    .load-more-article {
        margin-top: 10px;
        margin-bottom: 80px;
        @media ($mobile) {
            margin-bottom: 60px;
        }
    }
}

.authors-article-page {
    .pagination {
        margin-top: 10px;
    }
    .block-writer-right {
        padding: 15px;
        border-radius: 5px;
        border: 1px solid $gray5;
        margin-bottom: 30px;
        @media ($mobile) {
            margin-bottom: 20px;
        }
        .image-opinion {
            margin-bottom: 10px;
            img {
                border-radius: 5px;
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }
        .text {
            display: block;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.5;
            color: $gray1;
            margin-bottom: 15px;
            @media ($mobile) {
                margin-bottom: 15px;
            }
            @media (max-width: 300px) {
                text-align: center;
            }
        }
        .block-content {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            @media ($mobile) {
                margin-bottom: 0;
            }
            @media (max-width: 300px) {
                flex-wrap: wrap;
                justify-content: center;
            }
            .right-side {
                a,
                span {
                    display: block;
                    width: 70px;
                    height: 70px;
                    flex: 0 0 70px;
                    max-width: 70px;
                    @media (max-width: 300px) {
                        margin-bottom: 10px;
                    }
                }
                img {
                    border-radius: 50%;
                }
            }
            .left-side {
                padding-right: 10px;
                @media (max-width: 300px) {
                    display: flex;
                    justify-content: center;
                }
                .writer-name {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.25;
                    color: $blue2;
                    display: block;
                    margin-bottom: 8px;
                }
                .date {
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 1.67;
                    color: $gray4;
                }
            }
        }
    }
}