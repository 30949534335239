.header-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    iframe {
        margin-top: 20px;
        margin-bottom: 20px;
        @media($mobile) {
            margin-bottom: 10px;
        }
    }
}

.header {
    .search {
        position: relative;
        width: 337px;
        height: 50px;
        margin-right: auto;
        display: flex;
        align-items: center;
        @media($mobile) {
            display: none;
        }
        input.serach_text_box {
            padding-top: 14px;
            padding-bottom: 14px;
            background: none;
            border-radius: 5px;
            border: solid 1px $gray5;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            color: $gray9;
            padding-right: 15px;
            &::placeholder {
                opacity: .5;
                color: $gray9;
            }
        }
        .btn-submit {
            position: absolute;
            background: url('/Content/images/icons/search-header.svg') no-repeat;
            height: 22px;
            width: 22px;
            left: 15px;
        }
    }
    .top-area {
        display: flex;
        background-color: $gray8;
        height: 40px;
        @media($mobile) {
            height: auto;
            margin-bottom: 15px;
            background-color: transparent;
        }
        .right {
            flex: 0 0 66%;
            @media($mobile) {
                width: 100%;
                flex: 0 0 100%;
            }
            .date {
                display: flex !important;
                font-size: 12px;
                font-weight: normal;
                margin-left: 15px;
                align-items: center;
                @media ($mobile) {
                    display: none !important;
                }
                .text {
                    margin-left: 3px;
                }
                &:before {
                    content: '';
                    background-image: url('/Content/images/icons/time.svg');
                    height: 14px;
                    width: 14px;
                    margin-left: 5px;
                    display: block;
                }
                &::after {
                    content: '';
                    width: 1px;
                    height: 100%;
                    background-color: $gray6;
                    display: none;
                    margin-right: 15px;
                }
                &.no-border {
                    &::after {
                        display: none;
                    }
                }
                &.with-border {
                    &::after {
                        display: inline-block;
                    }
                }
            }
            .breaking-news {
                position: relative;
                width: 100%;
                @media($mobile) {
                    width: 100%;
                    overflow: hidden;
                }
                ul {
                    right: 0 !important;
                    li {
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 2;
                        color: $red1;
                        margin-bottom: 0;
                        display: block;
                        @media($mobile) {
                            font-size: 14px;
                            line-height: 1.71;
                        }
                        .news-center {
                            display: flex;
                            align-items: center;
                            height: 100%;
                        }
                    }
                }
            }
        }
        .left {
            flex: 0 0 34%;
            margin-right: auto;
            justify-content: end;
            @media($mobile) {
                flex: 0 0 100%;
            }
            .last-update {
                p,
                .first_row {
                    margin-bottom: 0;
                    font-size: 12px;
                    font-weight: normal;
                    line-height: normal;
                    color: $gray3;
                }
            }
            .social-media-header {
                margin-right: 30px;
                a {
                    text-decoration: none;
                    &:not(:last-child) {
                        padding-left: 20px;
                    }
                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
    .main-nav {
        padding: 20px 0;
        .logo {
            width: 250px;
            height: auto;
        }
        .static-banner {
            //width: 728px;
            //height: 90px;
            // max-width: 60vw;
            img {
                object-fit: contain;
            }
        }
    }
    .nav-items {
        padding: 15px 0;
        border-bottom: 2px solid $blue1;
        @media($mobile) {
            background: $white;
            border-bottom: 1px solid $gray6;
            border-top: 1px solid $gray6;
        }
        &.sticky {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 4;
            background-color: $white;
            .navbar-nav .nav-item.logo-menu .nav-link {
                width: 91px;
            }
        }
        .close-icon {
            padding-top: 30px;
            padding-right: 20px;
            padding-bottom: 12px;
            @media($desktop) {
                display: none;
            }
            img {
                width: 20px;
                height: 20px;
            }
        }
        .navbar-nav {
            padding-right: 0;
            align-items: center;
            @media($mobile) {
                height: auto;
                background: $white;
            }
            .nav-item {
                transition: .2s all ease-in-out;
                @media($mobile) {
                    width: 100%;
                }
                .nav-link {
                    color: $blue1;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-weight: bold;
                    @media (min-width: 992px) and (max-width: 1024px) {
                        padding: 0 10px;
                        font-size: 14px;
                    }
                    @media($mobile) {
                        padding: 15px 0;
                        margin-right: 20px;
                        margin-left: 40px;
                        border-bottom: 1px solid $gray7;
                        line-height: 18px;
                    }
                }
                &:not(.logo-menu) {
                    &:hover,
                    &.active {
                        background-color: $blue2;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        border-radius: 5px;
                        .nav-link {
                            color: $white;
                            @media($mobile) {
                                color: $blue1;
                            }
                        }
                        @media($mobile) {
                            background: none;
                            color: $blue1;
                            padding-top: 0;
                            padding-bottom: 0;
                            border-radius: 0;
                        }
                    }
                }
                &.logo-menu {
                    margin-left: 40px;
                    @media (min-width: 992px) and (max-width: 1024px) {
                        margin-left: 20px;
                    }
                    @media($mobile) {
                        display: none;
                    }
                    .nav-link {
                        padding: 0;
                        width: 198px;
                        height: auto;
                        @media (min-width: 992px) and (max-width: 1024px) {
                            width: 98px;
                        }
                    }
                }
            }
        }
        .container {
            position: relative;
            .navbar-brand {
                margin-right: 0;
                width: 102px;
                @media($desktop) {
                    display: none;
                }
            }
            .mobile-search {
                display: block;
                @media($desktop) {
                    display: none;
                }
                span {
                    height: 20px;
                    width: 20px;
                    display: block;
                    &:not(.hidden) {
                        background-image: url('/Content/images/icons/search-dark.svg');
                    }
                    &.hidden {
                        background-image: url('/Content/images/icons/close.svg');
                    }
                }
                .search-bar {
                    display: none;
                    &.show {
                        display: block;
                        position: absolute;
                        right: 20px;
                        top: 90px;
                        width: calc(100% - 40px);
                        z-index: 4;
                        height: calc(100vh - 80px);
                        background: $white;
                        outline: 19px solid #fff;
                        @media (min-width: 768px) and (max-width: 1024px) {
                            right: 0;
                            width: 100%;
                        }
                        input {
                            width: 100%;
                            border: none;
                            box-sizing: border-box;
                            height: 50px;
                            border: 1px solid $gray6;
                            border-radius: 5px;
                            font-size: 16px;
                            font-weight: bold;
                            color: $gray3;
                        }
                        .btn-submit {
                            background: url('/Content/images/icons/search-blue.svg') no-repeat;
                            height: 20px;
                            width: 20px;
                            position: absolute;
                            left: 15px;
                            top: 15px;
                            border: 0;
                        }
                    }
                }
            }
        }
        .social-media-header {
            margin: 30px 20px 0 51px;
            a {
                width: 24px;
                height: 24px;
            }
        }
        .navbar-toggler {
            padding: 0;
            &:focus {
                box-shadow: none;
            }
        }
        @media($mobile) {
            .navbar-toggler-icon {
                height: 20px;
                width: 20px;
                &:not(.hidden) {
                    background-image: url('/Content/images/icons/toggler.svg');
                }
                &.hidden {
                    background-image: url('/Content/images/icons/close.svg');
                }
            }
            .navbar-collapse {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(23, 23, 37, 0.5);
                overflow: hidden;
                display: none;
                >.menu-social-media {
                    width: 270px;
                    height: 100%;
                    background: $white;
                    position: relative;
                    right: -100%;
                }
                &.show {
                    display: block !important;
                    z-index: 10;
                    >.menu-social-media {
                        animation: slideRight 0.3s ease-in-out;
                        right: 0;
                    }
                }
                @keyframes slideRight {
                    0% {
                        right: -100%;
                    }
                    100% {
                        right: 0;
                    }
                }
            }
        }
    }
}

.title-separator {
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    color: $gray1;
    position: relative;
    display: block;
    margin-bottom: 20px;
    &:after {
        content: '';
        width: 48px;
        height: 3px;
        background: $blue2;
        position: absolute;
        bottom: -13px;
        right: 0;
        @media($mobile) {
            width: 100px;
        }
    }
}

.header .nav-items.sticky .navbar-nav .nav-item.logo-menu {
    display: block;
    @media($mobile) {
        display: none;
    }
}