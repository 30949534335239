footer {
    .top-footer {
        padding-top: 30px;
        padding-bottom: 20px;
        border-top: 1px solid $gray6;
        .footer-menu {
            @media($mobile) {
                .title-with-list {
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                    border-bottom: 1px solid $gray6;
                }
                .block-title {
                    display: flex;
                    align-items: center;
                    .title-separator {
                        margin-bottom: 0;
                        &:after {
                            display: none;
                        }
                    }
                    &:before {
                        content: '';
                        width: 16px;
                        height: 16px;
                        background-image: url('/Content/images/icons/plus.svg');
                        margin-left: 15px;
                    }
                    &.minus-icon {
                        &:before {
                            background-image: url('/Content/images/icons/minus.svg');
                        }
                    }
                }
            }
        }
        .footer-logo {
            margin-bottom: 23px;
            width: 256px;
            @media($mobile) {
                margin-bottom: 20px;
                width: 160px;
            }
        }
        .description {
            padding-left: 115px;
            @media($mobile) {
                padding-left: 0;
                margin-bottom: 25px;
            }
            p {
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                line-height: 1.71;
                color: $gray1;
            }
        }
        ul {
            padding: 0;
        }
        .quick-links {
            .nav-item {
                flex: 0 0 50%;
                &:first-child {
                    order: 1;
                }
                &:nth-child(2) {
                    order: 3;
                }
                &:nth-child(3) {
                    order: 5;
                }
                &:nth-child(4) {
                    order: 7;
                }
                &:nth-child(5) {
                    order: 9;
                }
                &:nth-child(6) {
                    order: 2;
                }
                &:nth-child(7) {
                    order: 4;
                }
                &:nth-child(8) {
                    order: 6;
                }
                &:nth-child(9) {
                    order: 8;
                }
            }
        }
        .information {
            flex-direction: column;
        }
        .nav-item {
            line-height: 2.1;
            @media($mobile) {
                width: 100%;
            }
        }
        .nav-link {
            padding: 0;
            color: $gray2;
            width: fit-content;
        }
        .newsletter-form {
            margin-top: 30px;
            margin-bottom: 30px;
            @media($mobile) {
                margin-bottom: 20px;
                padding-bottom: 30px;
                border-bottom: 1px solid $gray6;
            }
            p {
                margin-bottom: 20px;
                margin-top: 27px;
                color: $gray3;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.43;
            }
            .newsletter {
                .newsletter-input {
                    margin-left: 20px;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: normal;
                    color: $gray3;
                    border-radius: 5px;
                    border: 1px solid $gray6;
                    padding-right: 14px;
                    @media($mobile) {
                        margin-left: 15px;
                    }
                    &::placeholder {
                        padding: 0.375rem 0;
                    }
                }
                .newsletter-submit {
                    width: 100px;
                    height: 50px;
                    border-radius: 5px;
                    background: $blue2;
                    font-size: 16px;
                    font-weight: normal;
                    line-height: normal;
                    color: $white;
                    @media($mobile) {
                        width: 75px;
                        height: 40px;
                    }
                }
            }
        }
        .social-media-header {
            @media($mobile) {
                display: flex;
                justify-content: center;
            }
            a {
                text-decoration: none;
                &:not(:last-child) {
                    margin-left: 30px;
                }
                img {
                    width: 24px;
                    height: 24px;
                    @media($mobile) {
                        width: 28px;
                        height: 28px;
                    }
                }
            }
        }
    }
    .bottom-footer {
        border-top: 1px solid $gray6;
        padding: 7px 0;
        @media($mobile) {
            padding: 20px 0 15px;
        }
        .copyright-text {
            @media($mobile) {
                margin-bottom: 20px;
            }
            p {
                font-size: 12px;
                font-weight: normal;
                line-height: 1.67;
                margin-bottom: 0;
                color: $gray4;
                @media($mobile) {
                    text-align: center;
                }
            }
        }
        .copyright-logo {
            @media($mobile) {
                margin: auto
            }
            img {
                width: 127px;
            }
            span {
                font-size: 16px;
                font-weight: bold;
                line-height: normal;
                color: $gray1;
                margin-left: 5px;
            }
        }
    }
}