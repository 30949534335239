.search-pages-top {
    .row:first-of-type {
        @media ($mobile) {
            flex-direction: column-reverse;
        }
    }
}

.search-pages-right {
    margin-bottom: 20px;
    @media ($mobile) {
        margin-bottom: 20px;
    }
    .search-title {
        font-size: 40px;
        font-weight: bold;
        line-height: 0.75;
        color: $gray2;
        margin-bottom: 35px;
        @media ($mobile) {
            font-size: 24px;
            line-height: 1.5;
            margin-bottom: 30px;
        }
    }
    .form-outline-search {
        position: relative;
        margin-bottom: 30px;
        input {
            padding: 18px 15px 16px 20px;
            font-size: 16px;
            font-weight: bold;
            color: $gray3;
        }
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            top: 23px;
            left: 20px;
            pointer-events: none;
            background-image: url('/Content/images/icons/solid-search.svg');
            background-size: contain;
        }
    }
    .searchTitle {
        font-size: 14px;
        line-height: 2.14;
        color: $gray2;
        margin-bottom: 10px;
        @media ($mobile) {
            font-size: 12px;
            line-height: 2.5;
            margin-bottom: 0;
        }
    }
    .result-and-filter {
        align-items: center;
        .search-desc {
            @media ($mobile) {
                margin-bottom: 23px;
            }
            span {
                font-size: 32px;
                font-weight: bold;
                line-height: 1.6;
                @media ($mobile) {
                    font-size: 24px;
                    line-height: 1.5;
                }
                &:first-child {
                    color: $gray2;
                }
                &:last-child {
                    color: $blue2;
                }
            }
        }
    }
    .list-items-section {
        margin-top: 44px;
        @media ($mobile) {
            margin-top: 30px;
        }
    }
    .list-items-section .item .large-block-horizontal {
        margin-bottom: 20px;
    }
    .pagination {
        padding-top: 20px;
        @media ($mobile) {
            padding-top: 10px;
        }
    }
}

.search-side-bar {
    .social-media {
        margin-bottom: 30px;
        @media ($mobile) {
            margin-top: 30px;
        }
    }
}

.advanced-search {
    padding: 20px 30px 40px;
    border: solid 1px $gray5;
    margin-bottom: 30px;
    @media ($mobile) {
        padding: 24px 20px 30px;
        margin-bottom: 21px;
        height: 71px;
        overflow: hidden;
        .top-advanced-search {
            .title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 0;
                &::after {
                    content: '';
                    background-image: url('/Content/images/icons/solid-arrow-up.svg');
                    background-size: contain;
                    transform: scaleY(-1);
                    display: block;
                    width: 18px;
                    height: 18px;
                    margin-top: 7px;
                }
            }
        }
    }
    &.show-advanced {
        height: auto;
        overflow: auto;
        .top-advanced-search {
            .title {
                margin-bottom: 30px;
                &::after {
                    transform: scaleY(1);
                }
            }
        }
    }
    input {
        font-size: 14px;
        color: $gray3;
        padding: 14px 14px 13px 15px;
        width: 100%;
    }
    .advancedTextBox {
        &.redBordered {
            border: solid 1px red;
            background-color: #ebccd1;
        }
    }
    .title.with-line .inner-title {
        padding-bottom: 23px;
        @media ($mobile) {
            padding-bottom: 13px;
        }
    }
    .top-advanced-search {
        margin-bottom: 20px;
        .title {
            margin-bottom: 42px;
            @media ($mobile) {
                margin-bottom: 30px;
            }
        }
        input {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
    .middle-advanced-search {
        margin-bottom: 20px;
        .title {
            margin-bottom: 25px;
            @media ($mobile) {
                margin-bottom: 44px;
            }
        }
    }
    .bottom-advanced-search {
        margin-bottom: 30px;
        .title {
            margin-bottom: 23px;
            @media ($mobile) {
                margin-bottom: 40px;
            }
        }
        .form-date,
        .to-date {
            position: relative;
            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 14px;
                height: 14px;
                top: 18px;
                left: 15px;
                background-image: url('/Content/images/icons/solid-calendar.svg');
                background-size: cover;
                pointer-events: none;
            }
            &.form-date {
                margin-bottom: 20px;
            }
        }
    }
    .submit-btn {
        cursor: pointer;
        padding: 15px 10px 18px;
        background-color: $blue2;
        width: 100%;
        border: none;
        border-radius: 5px;
        color: $white;
        font-size: 16px;
        text-align: center;
    }
}

select#sortOptions {
    opacity: 0;
}