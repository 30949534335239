/* General Classes */

.static {
    margin-bottom: 200px;
    @media($mobile) {
        margin-bottom: 60px;
    }
}

.static-title {
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    color: $gray1;
    margin-bottom: 30px;
    @media($mobile) {
        font-size: 24px;
        margin-bottom: 20px;
    }
}

.static-description {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    color: $gray3;
    padding-left: 105px;
    @media($mobile) {
        font-size: 16px;
        line-height: 1.75;
        padding-left: 0;
    }
}

.static-box {
    background: $gray8;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 460px;
    @media($mobile) {
        height: 320px;
        margin-top: 30px;
    }
}

.contact-info {
    .contact-info-list {
        li {
            display: flex;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            line-height: normal;
            color: $gray3;
            &:not(:last-child) {
                margin-bottom: 15px;
            }
            @media($mobile) {
                font-size: 18px;
                line-height: 1.67;
            }
            &:before {
                content: '';
                width: 40px;
                min-width: 40px;
                height: 40px;
                margin-left: 15px;
            }
            &.address {
                &:before {
                    background: url('/Content/images/icons/location.svg') no-repeat;
                }
            }
            &.phone {
                &:before {
                    background: url('/Content/images/icons/phone.svg') no-repeat;
                }
            }
            &.email {
                &:before {
                    background: url('/Content/images/icons/email.svg') no-repeat;
                }
            }
            &.fax {
                &:before {
                    background: url('/Content/images/icons/fax.svg') no-repeat;
                }
            }
        }
    }
}

.sub-title {
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    color: $gray1;
    margin-top: 22px;
    margin-bottom: 35px;
}


/* Start : advertise page */

.advertise {
    .advertise-box {
        img {
            width: 250px;
            height: 250px;
            @media($mobile) {
                height: 160px;
                width: 160px;
            }
        }
    }
}


/* End : advertise page */


/* Start : copyrights page */

.copyrights {
    .static-description {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    .copyright-image {
        display: block;
        width: 213.9px;
        height: 77px;
        @media($mobile) {
            margin: auto;
        }
    }
    .copyright-box {
        img {
            @media($mobile) {
                width: 151.3px;
                height: 160px;
            }
        }
    }
}


/* End : copyrights page */


/* Start : contact us page */

.contact-us {
    margin-bottom: 40px;
    @media($mobile) {
        margin-bottom: 60px;
    }
    .contact-us-info {
        margin-bottom: 30px;
        @media($mobile) {
            margin-bottom: 20px;
        }
        .static-title {
            margin-bottom: 15px;
        }
        p {
            font-size: 18px;
            font-weight: normal;
            line-height: 1.78;
            color: $gray3;
            margin-top: 26px;
            @media($mobile) {
                margin-top: 20px;
                font-size: 16px;
                line-height: 1.75;
            }
        }
        .sub-title {
            margin-top: 23px;
            margin-bottom: 20px;
        }
    }
    .contact-img {
        width: 460px;
        height: 460px;
        background-color: $gray8;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 200px;
        @media($mobile) {
            width: 100%;
            height: 320px;
            margin-bottom: 60px;
        }
        img {
            display: block;
            width: 250px;
            height: 250px;
            position: absolute;
            @media($mobile) {
                width: 160px;
                height: 160px;
            }
        }
    }
}


/* End : contact us page */


/* Start : about us page */

.about-us {
    margin-bottom: 70px;
    @media($mobile) {
        margin-bottom: 30px;
    }
    .about-us-info {
        .static-title {
            margin-bottom: 20px;
        }
    }
    .authors-wrraper {
        display: flex;
        flex-wrap: wrap;
        .author {
            display: flex;
            align-items: center;
            flex: 0 0 33.333%;
            max-width: 33.333%;
            @media($mobile) {
                flex: 100%;
                max-width: 100%;
                margin-top: 15px;
            }
            .author-image {
                width: 100px;
                height: 100px;
                margin-left: 20px;
                @media($mobile) {
                    width: 70px;
                    height: 70px;
                    margin-left: 10px;
                }
                img {
                    border-radius: 50%;
                }
            }
            .author-name {
                .sub-title {
                    margin-bottom: 5px;
                    color: $blue2;
                    margin-top: 0;
                    @media($mobile) {
                        font-size: 20px;
                        margin-bottom: 10px;
                        margin-top: 0;
                    }
                }
                p {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 1.43;
                    color: $gray2;
                    span {
                        color: $blue2;
                        font-weight: bold;
                        cursor: pointer;
                        padding-right: 5px;
                    }
                }
            }
        }
    }
    .team-info {
        >.sub-title {
            margin-bottom: 15px;
        }
    }
    .team {
        >.sub-title {
            margin-top: 30px;
            margin-bottom: 30px;
        }
        .team-memeber {
            display: flex;
            padding-bottom: 17px;
            margin-bottom: 13px;
            @media($mobile) {
                flex-direction: column;
            }
            &:not(:last-child) {
                border-bottom: 1px solid $gray6;
            }
            .category-name {
                flex: 0 0 150px;
                max-width: 150px;
                color: $gray3;
                @media($mobile) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-bottom: 3px;
                }
            }
            .team-name {
                flex: 0 0 calc(100% - 150px);
                max-width: calc(100% - 150px);
                @media($mobile) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                a {
                    color: $blue2;
                    &:not(:first-child) {
                        &::before {
                            content: "-";
                            padding-right: 10px;
                            padding-left: 10px;
                        }
                    }
                }
            }
            .category-name,
            .team-name a {
                font-size: 20px;
                font-weight: bold;
                @media($mobile) {
                    font-size: 16px;
                }
            }
        }
    }
    .logo,
    .subscribe-sction {
        margin-bottom: 40px;
        @media($mobile) {
            margin-bottom: 30px;
        }
    }
    .logo {
        width: 460px;
        margin-top: 12px;
        @media($mobile) {
            width: 100%;
        }
    }
}


/* End : about us page */

.download-vacancy {
    margin-top: 45px;
    @media($mobile) {
        margin-top: 70px;
        margin-bottom: 120px;
    }
    a {
        padding: 14px 21px;
        border: solid 1px $gray7;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        flex-wrap: wrap;
        .title {
            font-size: 16px;
            font-weight: normal;
            line-height: 2;
            color: $gray3;
            @media($mobile) {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 14px;
            }
        }
        .download {
            font-size: 14px;
            font-weight: bold;
            line-height: 2.29;
            color: $gray5;
            display: flex;
            align-items: center;
            @media($mobile) {
                flex: 0 0 100%;
                max-width: 100%;
            }
            &::before {
                content: '';
                background-image: url('/Content/images/icons/solid-download.svg');
                width: 24px;
                height: 24px;
                display: inline-block;
                margin-left: 10px;
            }
        }
        &:hover {
            border: solid 1px $blue2;
            background-color: $blue3;
            .download {
                color: $blue2;
                &::before {
                    background-image: url('/Content/images/icons/solid-download-on.svg');
                }
            }
        }
    }
}


/* End : about us page */