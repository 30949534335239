.detailed-page {
    .main-title {
        font-size: 40px;
        font-weight: bold;
        line-height: 1.5;
        color: $gray1;
        margin-bottom: 15px;
        @media ($mobile) {
            font-size: 24px;
            line-height: 1.5;
        }
    }
    .title-details {
        display: flex;
        align-items: center;
        margin-bottom: 35px;
        @media ($mobile) {
            margin-bottom: 15px;
        }
        .author-and-section {
            .opinion-article {
                a {
                    display: block;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 1.14;
                    color: #0f52aa;
                }
            }
        }
        span {
            font-size: 14px;
            color: $gray3;
            @media ($mobile) {
                font-size: 12px;
            }
            a {
                font-size: 14px;
                color: $gray3;
                @media ($mobile) {
                    font-size: 12px;
                }
            }
            &:not(:first-child) {
                margin-right: 10px;
                padding-right: 10px;
                border-right: 1px solid $gray3;
            }
        }
    }
    .image-with-desc {
        margin-bottom: 40px;
        @media ($mobile) {
            margin-bottom: 22px;
        }
        .image-large {
            height: 535px;
            @media (min-width: 768px) and (max-width: 1024px) {
                height: auto;
            }
            @media (max-width:767px) {
                height: auto;
            }
        }
        .caption-image {
            span {
                display: block;
                padding: 15px 19px;
                background-color: $blue3;
                font-size: 16px;
                font-weight: bold;
                color: $blue2;
                @media ($mobile) {
                    font-size: 12px;
                    padding: 11px 10px;
                }
            }
        }
    }
    .top-fontsize-and-share-with {
        margin-top: 36px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 23px;
        @media ($mobile) {
            flex-direction: column-reverse;
            margin-top: 30px;
            margin-bottom: 20px;
            justify-content: flex-start;
            align-items: baseline;
        }
        .share-with {
            @media ($mobile) {
                padding-bottom: 17px;
                margin-bottom: 20px;
                border-bottom: 1px solid $gray6;
            }
        }
    }
    .bottom-fontsize-and-share-with {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        @media ($mobile) {
            margin-bottom: 27px;
        }
        .share-with {
            @media ($mobile) {
                padding-top: 23px;
                margin-top: 20px;
                border-top: 1px solid $gray6;
            }
        }
    }
    .body-content-style {
        font-family: 'ArabicUIText';
        font-size: 20px;
        line-height: 1.6;
        color: $gray1;
        margin-bottom: 30px;
        font-weight: 400;
        @media ($mobile) {
            font-size: 18px;
            line-height: 1.78;
        }
        strong,
        b {
            font-weight: bold !important;
        }
        em {
            font-style: italic !important;
        }
        // span {
        //     text-align: unset !important;
        //     text-transform: unset !important;
        //     text-indent: unset !important;
        //     letter-spacing: unset !important;
        //     display: inline !important;
        //     font: unset !important;
        //     white-space: unset !important;
        //     float: unset !important;
        //     color: unset !important;
        //     word-spacing: unset !important;
        //     -webkit-text-stroke-width: unset !important;
        // }
    }
    .fb-comment-section {
        margin-bottom: 30px;
        @media ($mobile) {
            margin-bottom: 30px;
        }
        .title {
            margin-bottom: 42px;
            @media ($mobile) {
                margin-bottom: 19px;
            }
        }
        .hint {
            margin-bottom: 10px;
        }
    }
    #MostReadAndSentItems {
        margin-bottom: 30px;
        .inner-title {
            padding-bottom: 17px;
            @media ($mobile) {
                padding-bottom: 13px;
            }
        }
        ul.ui-tabs-tab {
            margin-top: 29px;
            @media ($mobile) {
                margin-top: 21px;
            }
            li {
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 30px;
                    @media ($mobile) {
                        margin-bottom: 40px;
                    }
                }
                .author {
                    .date {
                        font-size: 14px;
                        color: $gray4;
                        padding-left: 10px;
                        margin-left: 10px;
                        border-left: 1px solid $gray4;
                        @media ($mobile) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
    .social-media {
        margin-bottom: 30px;
    }
}

.maybe-like-widget {
    margin-bottom: 40px;
    @media ($mobile) {
        margin-bottom: 30px;
        max-width: 100%;
    }
    .title,
    .spk-header {
        padding: 0;
        border-inline-start: 0;
        width: fit-content;
        border-bottom: 6px solid $blue2 !important;
        font-size: 24px !important;
        font-weight: bold;
        color: $gray1 !important;
        padding-bottom: 20px;
        @media ($mobile) {
            font-size: 18px !important;
            padding-bottom: 13px;
            border-bottom: 4px solid $blue2 !important;
        }
        margin-bottom: 34px;
        @media ($mobile) {
            margin-bottom: 30px;
        }
    }
    .sp-top-bar {
        text-align: right;
        padding: 0;
        margin-bottom: 14px;
    }
    .sp-wi-items {
        margin-right: -5px !important;
        margin-left: -5px !important;
        .article-box {
            margin: 0 !important;
            margin-right: 5px !important;
            margin-left: 5px !important;
            margin-bottom: 10px !important;
        }
    }
    #sp-wi-6665 .sp-wi-item {
        @media ($mobile) {
            width: 100%!important;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.writer-sidebar {
    display: none;
    padding: 20px 19px 30px;
    border: solid 1px $gray5;
    margin-bottom: 30px;
    @media ($mobile) {
        margin-bottom: 26px;
        padding: 0;
        border: none;
    }
    .title {
        margin-bottom: 22px;
        @media ($mobile) {
            margin-bottom: 21px;
        }
    }
    .block-content {
        display: flex;
        align-items: center;
        margin-bottom: 27px;
        @media ($mobile) {
            margin-bottom: 20px;
        }
        .right-side {
            width: 90px;
            height: 90px;
            flex: 0 0 90px;
            max-width: 90px;
            @media ($mobile) {
                width: 70px;
                height: 70px;
                flex: 0 0 70px;
                max-width: 70px;
            }
            img {
                border-radius: 50%;
                border: solid 1px #f3f3f4;
                object-fit: none;
            }
        }
        .left-side {
            padding-right: 10px;
            .text {
                display: block;
                font-size: 20px;
                font-weight: bold;
                line-height: 1.5;
                color: $blue2;
                margin-bottom: 5px;
                @media ($mobile) {
                    font-size: 18px;
                    line-height: 1.67;
                    margin-bottom: 0;
                }
            }
            .writer-name {
                a {
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 1.43;
                    color: $gray2;
                    @media ($mobile) {
                        font-size: 12px;
                        line-height: 1.67;
                    }
                }
            }
        }
    }
    .title-writer {
        position: relative;
        margin-bottom: 5px;
        @media ($mobile) {
            margin-bottom: 6px;
        }
        span {
            font-size: 16px;
            font-weight: bold;
            color: $gray3;
            background-color: $white;
            padding-left: 19px;
            z-index: 2;
            width: fit-content;
            position: relative;
            @media ($mobile) {
                font-size: 14px;
                padding-left: 10px;
            }
        }
        &::after {
            content: '';
            width: 100%;
            display: block;
            border-bottom: 1px dashed $gray5;
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 1;
        }
    }
    .other-articles-by-author {
        li {
            padding-top: 15px;
            padding-bottom: 19px;
            @media ($mobile) {
                padding-top: 14px;
                padding-bottom: 15px;
            }
            &:not(:last-of-type) {
                border-bottom: 1px dashed $gray5;
            }
            .title-other {
                display: block;
                font-size: 16px;
                font-weight: bold;
                line-height: 2.13;
                color: $gray1;
                margin-bottom: 10px;
                @media ($mobile) {
                    font-size: 14px;
                    line-height: 1.6;
                }
            }
            .date {
                font-size: 14px;
                color: $gray4;
                @media ($mobile) {
                    font-size: 12px;
                }
            }
        }
    }
    .read-more {
        margin-top: 8px;
        @media ($mobile) {
            margin-top: 5px;
        }
    }
}

.related-articles {
    margin-bottom: 30px;
    .title {
        margin-bottom: 42px;
        @media ($mobile) {
            margin-bottom: 30px;
        }
    }
    ul {
        li {
            &:not(:last-child) {
                padding-bottom: 20px;
                margin-bottom: 20px;
                border-bottom: 1px solid $gray6;
                border-width: calc(100% - 18px);
                @media ($mobile) {
                    padding-bottom: 0;
                    border-bottom: none;
                }
            }
            .right-img-left-desc {
                align-items: center;
                @media ($mobile) {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
            .right-side {
                height: 110px;
                @media (min-width: 768px) and (max-width: 1024px) {
                    height: auto;
                }
                @media (max-width:767px) {
                    height: auto;
                }
                @media ($mobile) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding-right: 0;
                    padding-left: 0;
                    margin-bottom: 10px;
                }
            }
            .left-side {
                height: auto;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 14px;
                @media ($mobile) {
                    height: auto;
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding-right: 0;
                    padding-left: 0;
                    margin-bottom: 0;
                }
                h2 {
                    margin-top: 4px;
                    margin-bottom: 0;
                    @media ($mobile) {
                        height: auto;
                        margin-bottom: 10px;
                    }
                    a {
                        @media ($mobile) {
                            font-size: 18px;
                            line-height: 1.78;
                        }
                    }
                }
            }
        }
    }
}

.body-content-style {
    iframe {
        max-width: 100%;
    }
}

.body-content-style {
    img {
        @media ($mobile) {
            width: 100%;
            object-fit: contain;
            height: auto !important;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}