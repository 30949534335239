.auther-page,
.blogs-page {
    margin-bottom: 30px;
    @media ($mobile) {
        margin-bottom: 0;
    }
    .auther-info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 30px;
        @media ($mobile) {
            flex-direction: column;
            justify-content: center;
        }
        .right-side {
            width: 140px;
            height: 140px;
            margin-left: 20px;
            @media ($mobile) {
                margin-left: 0;
                margin-bottom: 10px;
            }
            img {
                border-radius: 50%;
                border: 1px solid $gray7;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .left-side {
            .auther-name {
                margin-bottom: 10px;
                @media ($mobile) {
                    margin-bottom: 0;
                }
                font-size: 32px;
                font-weight: bold;
                color: $blue2;
                @media ($mobile) {
                    font-size: 24px;
                    text-align: center;
                }
            }
            .auther-job {
                font-size: 14px;
                line-height: 1.43;
                color: $gray2;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 200px;
                @media ($mobile) {
                    text-align: center;
                }
            }
        }
    }
    .social-media {
        margin-top: 0;
        @media ($mobile) {
            margin-top: 30px;
        }
    }
    .author-articles {
        .list-items-section {
            margin-top: 32px;
            @media ($mobile) {
                margin-top: 30px;
            }
            .item .large-block-horizontal .left-side .details .author .date {
                border-left: 0;
            }
        }
    }
}

.blogs-page {
    .list-items-section {
        margin-top: 0;
    }
}

.authorGrid .noArticles {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    color: $blue2;
    @media ($mobile) {
        font-size: 24px;
        text-align: center;
    }
}