.caricatures-page {
    .title-caricatures-wrapper {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 35px;
        @media ($mobile) {
            margin-bottom: 20px;
        }
        .main-title-details {
            .main-title {
                font-size: 40px;
                font-weight: bold;
                line-height: 1.1;
                color: $gray1;
                margin-bottom: 15px;
                @media ($mobile) {
                    font-size: 24px;
                    line-height: 1.83;
                    margin-bottom: 5px;
                }
            }
            .title-details {
                display: flex;
                align-items: center;
                @media ($mobile) {
                    margin-bottom: 20px;
                }
                span {
                    font-size: 14px;
                    color: $gray3;
                    @media ($mobile) {
                        font-size: 12px;
                    }
                    &:not(:first-child) {
                        margin-right: 10px;
                        padding-right: 10px;
                        border-right: 1px solid $gray3;
                    }
                    &.viewsCounter {
                        display: none;
                    }
                }
            }
        }
    }
    .image-with-desc {
        margin-bottom: 40px;
        @media ($mobile) {
            margin-bottom: 22px;
        }
        .image-large {
            height: 535px;
            @media (min-width: 768px) and ($mobile) {
                height: auto;
            }
            @media (max-width: 767px) {
                height: 210px;
            }
            @media (max-width: 360px) {
                height: auto;
            }
        }
    }
    .maybe-like-widget {
        margin-top: 40px;
        @media ($mobile) {
            max-width: 100%;
        }
    }
    .watch-also-sction {
        margin-bottom: 100px;
        @media ($mobile) {
            margin-bottom: 30px;
        }
        .title {
            margin-bottom: 32px;
            @media ($mobile) {
                margin-bottom: 20px;
            }
        }
        .pagination {
            margin-top: 10px;
        }
    }
}

.caricatures-sidebar {
    .writer-sidebar {
        padding-bottom: 0;
    }
    .readmore-sction {
        .title {
            margin-bottom: 23px;
        }
    }
    .social-media {
        margin-bottom: 30px;
    }
}