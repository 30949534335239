* {
    @include prefixer(box-sizing, border-box, webkit moz);
}

.shown-tablet {
    display: none;
}

.clearfix {
    clear: both;
}

.row>* {
    padding-right: 15px;
    padding-left: 15px;
}

.row {
    margin-left: -15px;
    margin-right: -15px;
}

.pagination {
    .page-link {
        padding: 0;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray3;
        border-radius: 5px;
        margin: 0 10px;
        @media ($mobile) {
            margin: 0 6px;
        }
        &:focus,
        &:hover {
            box-shadow: none;
            background-color: #e5effa;
            border: solid 1px #0f52aa;
        }
    }
    .page-item {
        cursor: pointer;
        &.disabled {
            cursor: default;
            a[aria-label="Previous"] {
                span {
                    &::before {
                        background-image: url('/Content/images/icons/arrow-left-disable.svg');
                    }
                }
            }
            a[aria-label="Next"] {
                span {
                    &::after {
                        background-image: url('/Content/images/icons/arrow-left-disable.svg');
                    }
                }
            }
        }
        &.active {
            .page-link {
                &:focus,
                &:hover {
                    box-shadow: none;
                    background-color: #0d6efd;
                    border-color: #0d6efd;
                }
            }
        }
    }
    .page-item:not(:first-child) .page-link {
        margin: 0 10px;
        @media ($mobile) {
            margin: 0 6px;
        }
    }
    a[aria-label="Previous"] {
        @media ($mobile) {
            //width: 77px;
        }
        span {
            font-size: 0;
            @media ($mobile) {
                //font-size: 14px;
                display: flex;
                align-items: center;
            }
            &::before {
                content: '';
                background-image: url('/Content/images/icons/arrow-left.svg');
                background-size: cover;
                transform: scaleX(-1);
                display: block;
                width: 15px;
                height: 15px;
                margin-left: 5px;
            }
        }
    }
    a[aria-label="Next"] {
        @media ($mobile) {
            //width: 77px;
        }
        span {
            font-size: 0;
            @media ($mobile) {
                //font-size: 14px;
                display: flex;
                align-items: center;
            }
            &::after {
                content: '';
                background-image: url('/Content/images/icons/arrow-left.svg');
                background-size: cover;
                display: block;
                width: 15px;
                height: 15px;
                margin-right: 5px;
            }
        }
    }
}

.breadcrumb {
    margin-bottom: 0;
    .breadcrumb-item {
        margin-top: 16px;
        margin-bottom: 40px;
        @media ($mobile) {
            margin-top: 15px;
            margin-bottom: 20px;
        }
        a {
            color: $gray4;
            font-size: 12px;
            display: block;
            line-height: normal;
        }
        &+.breadcrumb-item {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: $gray4;
            line-height: normal;
            &::before {
                float: right;
                margin: 0 8px;
                padding: 0;
                content: '';
                background-image: url('/Content/images/icons/arrow-left.svg');
                background-size: cover;
                display: block;
                width: 12px;
                height: 12px;
            }
        }
    }
}

.form-select {
    background-position: left 0.75rem center;
    padding: 14px 15px 13px;
    font-size: 14px;
    color: $gray3;
    border-radius: 4px;
    border-color: $gray6;
    background-image: url('/Content/images/icons/arrow-down.svg');
    &:focus {
        box-shadow: none !important;
    }
}

.container {
    max-width: 1440px;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: 1200px) and (max-width: 1399px) {
        max-width: 1140px;
    }
    @media (min-width:992px) and (max-width: 1199px) {
        max-width: 960px;
    }
    @media (min-width:768px) and (max-width:991px) {
        max-width: 720px;
    }
    @media (max-width:767px) {
        max-width: 100%;
        padding-right: 20px;
        padding-left: 20px;
    }
}

a,
picture {
    >img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

img {
    max-width: 100%;
}

.author-and-section {
    display: flex;
    align-items: center;
    .opinion-article {
        display: flex;
        align-items: center;
        .section {
            font-size: 14px;
            font-weight: normal;
            color: $white;
            padding: 2px 10px 4px;
            border-radius: 5px;
            margin-left: 10px;
            background-color: #ffad1f;
            @media ($mobile) {
                font-size: 12px;
                padding: 2px 10px 4px;
                border-radius: 6px;
            }
        }
    }
}

.title.with-line {
    border-bottom: 1px solid $gray6;
    .inner-title {
        width: fit-content;
        border-bottom: 6px solid $blue2;
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
        color: $gray1;
        padding-bottom: 20px;
        @media ($mobile) {
            font-size: 18px;
            padding-bottom: 13px;
            border-bottom: 4px solid $blue2;
        }
        a {
            font-size: 24px;
            font-weight: bold;
            color: $gray1;
            @media ($mobile) {
                font-size: 18px;
            }
        }
    }
}

.ads-block {
    text-align: center;
    .web-view {
        width: 970px;
        height: 90px;
        margin: 0 auto;
        @media ($mobile) {
            display: none;
        }
    }
    .mobile-view {
        display: none;
        width: 100%;
        height: 320px;
        @media ($mobile) {
            display: block;
        }
    }
}

.ads-block-private-files-top {
    text-align: center;
    .web-view {
        width: 1168px;
        height: 200px;
        margin: 0 auto;
        @media (max-width: 1199px) {
            display: none;
        }
    }
    .mobile-view {
        display: none;
        width: 100%;
        height: 237px;
        @media ($mobile) {
            display: block;
        }
    }
}

.read-more {
    display: block;
    padding-top: 12px;
    padding-bottom: 18px;
    background-color: $blue3;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: $blue2;
    @media ($mobile) {
        padding-top: 9px;
        padding-bottom: 9px;
        font-size: 14px;
    }
}

.top-img-bottom-desc {
    margin-bottom: 30px;
    @media ($mobile) {
        margin-bottom: 22px;
    }
    .top-side {
        height: 260px;
        margin-bottom: 9px;
        @media (min-width: 768px) and (max-width: 1099px) {
            height: auto;
            margin-bottom: 10px;
        }
        @media (max-width:767px) {
            height: auto;
            margin-bottom: 10px;
        }
        img {
            border: solid 0.5px $gray7;
        }
    }
    .bottom-side {
        h2 {
            padding-top: 1px;
            height: 65px;
            overflow: hidden;
            @media ($mobile) {
                padding-top: 0;
                height: auto;
                margin-bottom: 10px;
            }
            a {
                font-size: 20px;
                font-weight: bold;
                line-height: 1.6;
                color: $gray1;
                @media ($mobile) {
                    font-size: 14px;
                    line-height: 1.57;
                }
            }
        }
        .author {
            a {
                font-size: 16px;
                line-height: 2;
                color: $gray4;
                @media ($mobile) {
                    font-size: 12px;
                    line-height: normal;
                }
            }
        }
    }
}

.top-img-bottom-desc-mobile {
    margin-bottom: 30px;
    @media ($mobile) {
        margin-bottom: 22px;
        display: flex;
    }
    .top-side {
        height: 260px;
        margin-bottom: 9px;
        @media (min-width: 768px) and (max-width: 1025px) {
            height: 75px;
            margin-bottom: 0;
            margin-left: 15px;
            flex: 0 0 120px;
            max-width: 120px;
        }
        @media (max-width:767px) {
            height: 75px;
            margin-bottom: 0;
            margin-left: 15px;
            flex: 0 0 120px;
            max-width: 120px;
        }
        img {
            border: solid 0.5px $gray7;
        }
    }
    .bottom-side {
        @media ($mobile) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        h2 {
            padding-top: 1px;
            height: 65px;
            overflow: hidden;
            @media ($mobile) {
                padding-top: 0;
                height: auto;
                margin-bottom: 10px;
                line-height: 0;
            }
            a {
                font-size: 20px;
                font-weight: bold;
                line-height: 1.6;
                color: $gray1;
                @media ($mobile) {
                    font-size: 14px;
                    line-height: 1.4;
                }
            }
        }
        .author {
            a {
                font-size: 16px;
                line-height: 2;
                color: $gray4;
                @media ($mobile) {
                    font-size: 12px;
                    line-height: normal;
                }
            }
        }
    }
}

.large-block-vertical {
    @media ($mobile) {
        margin-bottom: 20px;
    }
    .image-large {
        position: relative;
        height: 260px;
        margin-bottom: 10px;
        @media (min-width: 768px) and (max-width: 1024px) {
            height: auto;
        }
        @media (max-width:767px) {
            height: auto;
        }
        img {
            border: solid 0.5px $gray7;
        }
    }
    .details {
        h2 {
            @media ($mobile) {
                margin-bottom: 3px;
            }
            a {
                font-size: 24px;
                font-weight: bold;
                line-height: 1.67;
                color: $gray1;
                @media ($mobile) {
                    font-size: 18px;
                    line-height: 1.78;
                }
            }
        }
        .author-and-section {
            margin-bottom: 13px;
            @media ($mobile) {
                margin-bottom: 7px;
            }
        }
        .author {
            a {
                font-size: 16px;
                line-height: 2;
                color: $gray4;
                @media ($mobile) {
                    font-size: 14px;
                    line-height: 2.29;
                }
            }
        }
        .description {
            font-size: 16px;
            line-height: 1.75;
            color: $gray3;
            @media ($mobile) {
                font-size: 14px;
                line-height: 1.57;
            }
        }
    }
}

.right-img-left-desc {
    display: flex;
    flex-wrap: wrap;
    margin-right: -9px;
    margin-left: -9px;
    @media ($mobile) {
        margin-right: -7px;
        margin-left: -7px;
    }
    .right-side {
        height: 105px;
        flex: 0 0 39.333%;
        max-width: 39.333%;
        padding-right: 9px;
        padding-left: 9px;
        @media (min-width: 768px) and (max-width: 1024px) {
            height: auto;
        }
        @media ($mobile) {
            height: 75px;
            flex: 0 0 120px;
            max-width: 120px;
            margin-left: 15px;
            padding-right: 0;
            padding-left: 0;
        }
        img {
            border: solid 0.5px $gray7;
        }
    }
    .left-side {
        flex: 0 0 60.666%;
        max-width: 60.666%;
        padding-right: 9px;
        padding-left: 9px;
        @media ($mobile) {
            flex: 0 0 auto;
            max-width: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-right: 0;
            padding-left: 0;
        }
        h2 {
            padding-top: 1px;
            margin-bottom: 13px;
            @media ($mobile) {
                padding-top: 0;
                margin-bottom: 0;
            }
            a {
                font-size: 20px;
                font-weight: bold;
                line-height: 1.7;
                color: $gray1;
                display: block;
                max-height: 68px;
                overflow: hidden;
                @media ($mobile) {
                    max-height: unset;
                    font-size: 14px;
                    line-height: 1.4;
                }
            }
        }
        .author {
            a {
                font-size: 14px;
                color: $gray4;
                @media ($mobile) {
                    font-size: 12px;
                }
            }
        }
    }
}

.large-block-horizontal {
    margin-bottom: 30px;
    .image-large {
        img {
            border: solid 0.5px $gray7;
        }
        height: 260px;
        @media (min-width: 768px) and (max-width: 1024px) {
            height: auto;
        }
        @media (max-width:767px) {
            height: auto;
        }
    }
    .details {
        h2 {
            margin-bottom: 10px;
            @media ($mobile) {
                margin-bottom: 6px;
                margin-top: 5px;
            }
            a {
                font-size: 24px;
                font-weight: bold;
                line-height: 1.67;
                color: $gray1;
                @media ($mobile) {
                    font-size: 18px;
                    line-height: 1.78;
                }
            }
        }
        .author-and-section {
            margin-bottom: 10px;
            @media ($mobile) {
                margin-bottom: 13px;
            }
        }
        .author {
            a {
                font-size: 16px;
                line-height: 2;
                color: $gray4;
                @media ($mobile) {
                    font-size: 14px;
                    line-height: normal;
                }
            }
        }
        .description {
            font-size: 16px;
            line-height: 1.75;
            color: $gray3;
            @media ($mobile) {
                font-size: 14px;
                line-height: 1.57;
            }
        }
    }
}


/********** Start Slider **********/

.carousel-slick {
    display: none;
}

#carouselExampleInterval,
#carouselExampleInterval2,
#carouselExampleInterval4 {
    height: 610px;
    @media (max-width: 1025px) {
        height: calc(100% + 34px);
        margin-bottom: 0;
        overflow: hidden;
    }
    &#carouselExampleInterval {
        @media (max-width: 767px) {
            margin-left: -20px;
            margin-right: -20px;
        }
        .carousel-inner .carousel-item-slick {
            @media (max-width: 1025px) {
                padding: 0;
            }
            .content-slide {
                padding-bottom: 15px;
                .title {
                    font-size: 24px;
                    font-weight: bold;
                    margin-bottom: 0;
                    display: block;
                    color: $white;
                    @media (max-width: 1025px) {
                        order: 1;
                        font-size: 18px;
                        line-height: 1.56;
                        max-height: 56px;
                        overflow: hidden;
                    }
                }
            }
        }
    }
    #carouselExampleInterval4 {
        height: auto;
    }
    .carousel-inner {
        height: 100%;
        .carousel-item-slick {
            position: relative;
            height: 100%;
            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 280px;
                position: absolute;
                bottom: 0;
                left: 0;
                background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #052b5b);
                @media ($mobile) {
                    display: none;
                }
            }
            img {
                @media (max-width: 1025px) {
                    height: calc(100% - 34px);
                }
            }
            .content-slide {
                position: absolute;
                bottom: 0;
                color: $white;
                padding-right: 30px;
                padding-bottom: 30px;
                padding-left: 100px;
                z-index: 1;
                @media (max-width: 1025px) {
                    padding: 13px 20px 25px 13px;
                    display: flex;
                    flex-direction: column;
                    bottom: 0;
                    width: 100%;
                    &:before {
                        content: '';
                        width: 100%;
                        height: 100%;
                        bottom: 0;
                        left: 0;
                        z-index: 1;
                        display: block;
                        position: absolute;
                        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #052b5b 61%);
                    }
                }
                .section {
                    @media (max-width: 1025px) {
                        order: 3;
                        position: relative;
                        z-index: 3;
                        height: 21px;
                    }
                    a {
                        font-size: 14px;
                        font-weight: bold;
                        margin-bottom: 5px;
                        display: block;
                        color: $white;
                        @media (max-width: 1025px) {
                            order: 3;
                            color: $yellow1;
                            margin-top: 10px;
                            margin-bottom: 0;
                        }
                    }
                }
                .title {
                    @media (max-width: 1025px) {
                        order: 1;
                        padding-bottom: 0;
                        position: relative;
                        z-index: 3;
                    }
                    a {
                        font-size: 24px;
                        font-weight: bold;
                        margin-bottom: 0;
                        display: block;
                        color: $white;
                        @media (max-width: 1025px) {
                            order: 1;
                            font-size: 18px;
                            line-height: 1.56;
                            max-height: 56px;
                            overflow: hidden;
                        }
                    }
                }
                .description {
                    font-size: 16px;
                    margin-bottom: 0px;
                    @media (max-width: 1025px) {
                        display: none;
                        order: 2;
                        position: relative;
                        z-index: 3;
                        padding-top: 15px;
                        font-size: 14px;
                        line-height: 1.4;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;
                        /* number of lines to show */
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }
    }
    .slick-prev,
    .slick-next {
        width: 32px;
        height: 32px;
        z-index: 3;
        position: absolute;
        font-size: 0;
        border: 0;
        bottom: 13px;
        left: 30px;
        right: auto;
        background-color: $white;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 1025px) {
            width: 24px;
            height: 24px;
            left: 20px;
        }
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            transform: scaleX(-1);
            background-image: url('/Content/images/icons/arrow-blue.svg');
            background-size: contain;
            @media (max-width: 1025px) {
                width: 12px;
                height: 12px;
            }
        }
        &:hover {
            background-color: $yellow1;
            &::before {
                transform: scaleX(1);
                background-image: url('/Content/images/icons/arrow-white.svg');
            }
            &.slick-prev {
                &::before {
                    transform: scaleX(-1);
                    background-image: url('/Content/images/icons/arrow-white.svg');
                }
            }
        }
        &.slick-prev {
            left: 77px;
            @media (max-width: 1025px) {
                left: 54px;
            }
            &::before {
                transform: scaleX(1);
                background-image: url('/Content/images/icons/arrow-blue.svg');
            }
        }
    }
}

#carouselExampleInterval2 .carousel-inner .carousel-item-slick img {
    @media (max-width: 1025px) {
        height: 100% !important;
    }
}


/********** End Slider **********/


/******** Start subscribe ********/

.subscribe-sction {
    padding: 40px;
    border: solid 1px $gray5;
    margin-bottom: 24px;
    @media ($mobile) {
        padding: 30px 20px 30px;
        margin-bottom: 30px;
    }
    .icon-sub {
        width: 48px;
        height: 48px;
        margin-bottom: 23px;
        @media ($mobile) {
            width: 32px;
            height: 32px;
            margin-bottom: 20px;
        }
        img {
            object-fit: contain;
        }
    }
    h3 {
        font-size: 20px;
        font-weight: bold;
        color: $gray1;
        margin-bottom: 15px;
        @media ($mobile) {
            font-size: 14px;
        }
    }
    p {
        font-size: 16px;
        line-height: 1.5;
        color: $gray3;
        margin-bottom: 20px;
        @media ($mobile) {
            font-size: 14px;
            line-height: 1.71;
        }
    }
    input {
        border-radius: 4px;
        border: solid 1px $gray6;
        margin-bottom: 30px;
        width: 100%;
        padding: 13px 14px 14px;
        font-size: 14px;
        color: $gray2;
        &::placeholder {
            padding: 13px 0 14px;
            font-size: 14px;
            color: $gray3;
            margin-bottom: 20px;
        }
    }
    .subscribe {
        display: block;
        padding-top: 12px;
        padding-bottom: 18px;
        text-align: center;
        font-size: 16px;
        color: $white;
        border-radius: 5px;
        background-color: $blue2;
        width: 100%;
    }
}

.subscribe-sction-full {
    padding: 40px 122px;
    border: solid 1px $gray5;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @media ($mobile) {
        display: block;
        padding: 30px 20px 30px;
        margin-bottom: 40px;
    }
    .right-side {
        display: flex;
        align-items: center;
        @media ($mobile) {
            display: block;
        }
        .icon-sub {
            display: flex;
            width: 60px;
            height: 60px;
            margin-left: 20px;
            @media ($mobile) {
                width: 32px;
                height: 32px;
                margin-bottom: 20px;
                margin-left: 0;
            }
            img {
                object-fit: contain;
            }
        }
        .text-inner {
            h3 {
                font-size: 20px;
                font-weight: bold;
                color: $gray1;
                margin-bottom: 10px;
                @media ($mobile) {
                    font-size: 14px;
                    margin-bottom: 15px;
                }
            }
            p {
                font-size: 16px;
                line-height: 1.5;
                color: $gray3;
                @media ($mobile) {
                    font-size: 14px;
                    line-height: 1.71;
                    margin-bottom: 20px;
                }
            }
        }
    }
    .left-side {
        .newsletter {
            display: flex;
            align-items: center;
            @media ($mobile) {
                display: block;
            }
            input {
                border-radius: 4px;
                border: solid 1px $gray6;
                margin-left: 30px;
                width: 338px;
                padding: 13px 14px 7px 15px;
                font-size: 14px;
                color: $gray5;
                &::placeholder {
                    padding: 13px 14px 7px 15px;
                    color: $gray5;
                }
                @media ($mobile) {
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: 20px;
                }
            }
            .subscribe {
                display: block;
                padding-top: 7px;
                padding-bottom: 12px;
                text-align: center;
                font-size: 16px;
                color: $white;
                border-radius: 5px;
                background-color: $blue2;
                width: 215px;
                @media ($mobile) {
                    width: 100%;
                }
            }
        }
        #newsOutput {
            margin-top: 0;
            margin-bottom: 0;
            @media ($mobile) {
                margin-top: 5px;
                margin-bottom: 20px;
            }
        }
    }
}


/******** End subscribe ********/


/******** Start social ********/

.social-media {
    padding: 21px 54px 30px 55px;
    background-color: $blue2;
    margin-bottom: 44px;
    @media ($mobile) {
        padding: 0 20px 28px;
        margin-bottom: 30px;
    }
    h3 {
        font-size: 24px;
        font-weight: bold;
        line-height: 2.25;
        color: $white;
        margin-bottom: 12px;
        @media ($mobile) {
            font-size: 18px;
            line-height: 3;
            text-align: center;
        }
    }
    .social-icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;
        max-width: 100%;
        li {
            width: 32px;
            height: 32px;
            @media ($mobile) {
                width: 28px;
                height: 28px;
            }
            img {
                object-fit: contain;
                width: 32px;
                height: 32px;
            }
            &.whatsapp-item {
                //display: none;
            }
        }
    }
}


/******** End social ********/

.list-items-section {
    margin-top: 40px;
    @media ($mobile) {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .navigation {
        padding-top: 10px;
    }
    .item {
        .large-block-horizontal {
            margin-bottom: 40px;
            .left-right-wrapper {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-left: -15px;
                margin-right: -15px;
            }
            .right-side {
                flex: 0 0 37.552%;
                max-width: 37.552%;
                padding-left: 15px;
                padding-right: 15px;
                @media ($mobile) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                .image-large {
                    height: 190px;
                    @media (min-width: 768px) and (max-width: 1024px) {
                        height: auto;
                        margin-bottom: 5px;
                    }
                    @media (max-width:767px) {
                        height: auto;
                        margin-bottom: 5px;
                    }
                }
            }
            .left-side {
                flex: 0 0 62.448%;
                max-width: 62.448%;
                padding-left: 15px;
                padding-right: 15px;
                @media ($mobile) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                .details {
                    h2 {
                        margin-bottom: 10px;
                        @media ($mobile) {
                            margin-bottom: 6px;
                        }
                        a {
                            font-size: 22px;
                            font-weight: bold;
                            line-height: 1.55;
                            color: $gray1;
                            @media ($mobile) {
                                font-size: 18px;
                                line-height: 1.78;
                            }
                        }
                    }
                    .author-and-section {
                        margin-bottom: 15px;
                        @media ($mobile) {
                            margin-bottom: 13px;
                        }
                        .opinion-article {
                            a {
                                display: block;
                                font-size: 14px;
                                font-weight: bold;
                                line-height: 1.14;
                                color: #0f52aa;
                            }
                        }
                        >div {
                            &:not(:first-child) {
                                padding-right: 10px;
                                margin-right: 10px;
                                border-right: 1px solid $gray4;
                            }
                        }
                    }
                    .author {
                        margin-bottom: 0;
                        .date {
                            font-size: 14px;
                            color: $gray4;
                            line-height: normal;
                        }
                    }
                    .description {
                        line-height: 1.5;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        @media ($mobile) {
                            line-height: 1.57;
                        }
                    }
                }
            }
        }
    }
}

.fontsize-block {
    display: flex;
    align-items: center;
    a {
        display: block;
        width: 28px;
        height: 28px;
        cursor: pointer;
    }
    span {
        font-size: 16px;
        color: $gray3;
        margin-left: 15px;
        margin-right: 15px;
    }
}

.share-with {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .share-text {
        font-size: 16px;
        font-weight: bold;
        color: $blue2;
        margin-left: 27px;
        @media ($mobile) {
            margin-left: 34px;
        }
    }
    .a2a_kit {
        display: flex;
        a {
            padding: 0;
            display: block;
            &:not(:last-child) {
                margin-right: 15px;
                @media ($mobile) {
                    margin-right: 7.5px;
                }
            }
            span {
                display: none;
            }
            &::after {
                content: '';
                display: block;
                width: 40px;
                height: 40px;
                background-size: contain;
            }
            &.a2a_button_facebook {
                &::after {
                    background-image: url('/Content/images/icons/facebook-details.svg');
                }
            }
            &.a2a_button_twitter {
                &::after {
                    background-image: url('/Content/images/icons/twitter-details.svg');
                }
            }
            &.a2a_button_whatsapp {
                &::after {
                    background-image: url('/Content/images/icons/whatsapp-details.svg');
                }
            }
            &.a2a_dd {
                &::after {
                    background-image: url('/Content/images/icons/share-details.svg');
                }
            }
        }
    }
}

.img-with-desc-caricatures {
    margin-bottom: 30px;
    @media ($mobile) {
        margin-bottom: 20px;
    }
    .top-side {
        height: 270px;
        margin-bottom: 9px;
        @media (min-width: 768px) and (max-width: 1024px) {
            height: auto;
            margin-bottom: 10px;
        }
        @media (max-width:767px) {
            height: auto;
            height: auto;
            margin-bottom: 10px;
        }
        img {
            border: solid 0.5px $gray6;
        }
    }
    .bottom-side {
        h2 {
            margin-bottom: 7px;
            a {
                font-size: 18px;
                font-weight: bold;
                color: $gray2;
            }
        }
        .author-and-date {
            display: flex;
            align-items: center;
            span,
            a {
                font-size: 14px;
                line-height: 2.14;
                color: $gray4;
                display: flex;
                align-items: center;
                &::before {
                    content: '';
                    display: block;
                    width: 16px;
                    height: 16px;
                    margin-left: 10px;
                }
            }
            .date {
                margin-left: 20px;
                &::before {
                    background-image: url('/Content/images/icons/solid-calendar.svg');
                }
            }
            .author {
                &::before {
                    background-image: url('/Content/images/icons/solid-edit.svg');
                }
            }
        }
    }
}

.sidebar-ads {
    height: auto;
    margin-bottom: 60px;
    @media ($mobile) {
        height: auto;
        margin-bottom: 60px;
    }
}

.video_play_icon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: rgba($color: $gray1, $alpha: 0.4);
    >.video_play_icon {
        width: 50px;
        height: 50px;
        position: absolute;
        top: calc(50% - 25px);
        right: calc(50% - 25px);
        background-color: transparent;
        @media ($mobile) {
            width: 50px;
            height: 50px;
            top: calc(50% - 25px);
            right: calc(50% - 25px);
            z-index: 9;
        }
        img {
            height: 100% !important;
            border: 0 !important;
        }
    }
}

.hide {
    display: none !important;
}

video {
    height: 100%;
}

.errorInfo {
    color: red;
    font-size: 14px;
    display: none;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
}

.noResult {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 50px 0;
}

.right-caricatures {
    .img-with-desc-caricatures {
        .top-side {
            height: 170px;
            @media (min-width: 768px) and (max-width: 1024px) {
                height: auto;
            }
            @media (max-width: 768px) {
                height: auto;
            }
        }
        .bottom-side {
            h2 {
                display: none;
            }
            .author-and-date {
                .date {
                    display: none;
                }
                .author {
                    font-size: 16px;
                    font-weight: bold;
                    color: $gray2;
                    line-height: normal;
                    @media ($mobile) {
                        font-size: 14px;
                        font-weight: normal;
                        line-height: 2.14;
                        color: $gray4;
                    }
                    &::before {
                        background-image: url("/Content/images/icons/blue-solid-edit.svg");
                        background-size: cover;
                    }
                }
            }
        }
    }
}

#newsOutput {
    margin-top: 5px;
    margin-bottom: 20px;
}

.detailed-page.caricatures-page {
    .caricatures-writer {
        @media ($mobile) {
            display: none !important;
        }
    }
}

.city-writers-section .city-writers.no-marquee .writers,
.city-writers-section .city-writers.no-marquee {
    height: unset;
}

.city-writers-section .city-writers.no-marquee .writers li:last-child {
    border-bottom: none;
}

@media (min-width: 1025px) and (max-width: 1199px) {
    .header .nav-items .navbar-nav .nav-item .nav-link {
        padding: 0 10px;
    }
    .city-writers-section .row {
        .col-lg-9,
        .col-lg-3 {
            width: 100%;
        }
    }
    .marquee-vertical {
        width: 100%;
    }
    .city-writers {
        margin-top: 30px;
    }
    .summary-pages .city-writers {
        margin-top: 0;
    }
    .summary-pages {
        .col-lg-8,
        .col-lg-4 {
            width: 100%;
        }
    }
    .social-media h3 {
        text-align: center;
    }
}

.slick-track,
.slick-list {
    @media (max-width: 1025px) {
        height: 100%;
    }
}

.show-web {
    display: block !important;
    @media (max-width: 1180px) {
        display: none !important;
    }
}

.show-ipad {
    display: none !important;
    @media (min-width: 1024px) and (max-width: 1180px) {
        display: block !important;
    }
}

.show-mobile {
    display: none !important;
    @media (max-width: 1023px) {
        display: block !important;
    }
}


div[id*='div-gpt-ad'] {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    text-align: center;
    margin-bottom: 30px;
    @media (max-width: 1023px) {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    div[id*='google_ads_iframe'] {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        // &:after {
        //     content: '';
        //     display: block;
        //     background-image: url('/Content/images/ads_word_black.svg');
        //     background-repeat: no-repeat;
        //     background-size: contain;
        //     width: 34px;
        //     height: 18px;
        //     margin-top: 2px;
        //     margin-left: auto;
        //     margin-right: auto;
        //     margin-top: 3px;
        //     position: absolute;
        //     bottom: 5px;
        //     left: calc(50% - 17px);
        // }
    }
}

.sticky-ads {
    @media (min-width: 1024px) {
        position: sticky;
        top: 90px;
        margin-bottom: 40px;
    }
    @media (max-width: 1023px) {
        display: none !important;
    }
}
.blogs-sction {
    .read-more {
        margin-top: 20px;
    }
}

.container {
    &:not(.home-page) {
        #div-gpt-ad-leaderboard-1 {
            margin-top: 10px;
            margin-bottom: 30px;
            height: 130px;
            background-color: #f9f9fa;
            padding-top: 15px;
            padding-bottom: 25px;
            margin-left: auto;
            margin-right: auto;
            width: 1020px;
            position: relative;
            display: block !important;
            @media(max-width: 1024px) {
                width: 100%;
                max-width: 100%;
            }
            @media (max-width: 1023px) {
                height: 169px;
            }
            div[id*='google_ads_iframe'] {
                margin-left: auto;
                margin-right: auto;
                &:after {
                    display: none !important;
                }
            }
            &:after {
                content: '';
                display: block;
                background-image: url('/Content/images/ads_word_black.svg');
                background-repeat: no-repeat;
                background-size: contain;
                width: 34px;
                height: 18px;
                margin-left: auto;
                margin-right: auto;
                position: absolute;
                bottom: 5px;
                left: calc(50% - 17px);
            }
        }
        #div-gpt-ad-leaderboard-2 {
            height: 130px;
            background-color: #f9f9fa;
            padding-top: 15px;
            padding-bottom: 25px;
            margin-left: auto;
            margin-right: auto;
            width: 768px;
            position: relative;
            display: block !important;
            @media(max-width: 1024px) {
                width: 100%;
                max-width: 100%;
            }
            @media (max-width: 1023px) {
                height: 169px;
            }
            div[id*='google_ads_iframe'] {
                margin-left: auto;
                margin-right: auto;
                &:after {
                    display: none !important;
                }
            }
            &:after {
                content: '';
                display: block;
                background-image: url('/Content/images/ads_word_black.svg');
                background-repeat: no-repeat;
                background-size: contain;
                width: 34px;
                height: 18px;
                margin-left: auto;
                margin-right: auto;
                position: absolute;
                bottom: 5px;
                left: calc(50% - 17px);
            }
        }
        div[id*='div-gpt-ad-billboard'] {
            height: 290px;
            background-color: #f9f9fa;
            padding-top: 15px;
            padding-bottom: 25px;
            margin-left: auto;
            margin-right: auto;
            width: 1020px;
            position: relative;
            display: block !important;
            @media(max-width: 1024px) {
                width: 100%;
                max-width: 100%;
            }
            @media (max-width: 1023px) {
                height: 640px;
            }
            div[id*='google_ads_iframe'] {
                margin-left: auto;
                margin-right: auto;
                &:after {
                    display: none !important;
                }
            }
            &:after {
                content: '';
                display: block;
                background-image: url('/Content/images/ads_word_black.svg');
                background-repeat: no-repeat;
                background-size: contain;
                width: 34px;
                height: 18px;
                margin-left: auto;
                margin-right: auto;
                position: absolute;
                bottom: 5px;
                left: calc(50% - 17px);
            }
        }
        #div-gpt-ad-medium-1 {
            height: 290px;
            background-color: #f9f9fa;
            padding-top: 15px;
            padding-bottom: 25px;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            position: relative;
            display: block !important;
            @media (max-width: 1023px) {
                height: 290px;
            }
            div[id*='google_ads_iframe'] {
                margin-left: auto;
                margin-right: auto;
                &:after {
                    display: none !important;
                }
            }
            &:after {
                content: '';
                display: block;
                background-image: url('/Content/images/ads_word_black.svg');
                background-repeat: no-repeat;
                background-size: contain;
                width: 34px;
                height: 18px;
                margin-left: auto;
                margin-right: auto;
                position: absolute;
                bottom: 5px;
                left: calc(50% - 17px);
            }
        }
        #div-gpt-ad-half-page-1 {
            height: 640px;
            background-color: #f9f9fa;
            padding-top: 15px;
            padding-bottom: 25px;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            position: relative;
            display: block !important;
            @media (max-width: 1023px) {
                height: 640px;
            }
            div[id*='google_ads_iframe'] {
                margin-left: auto;
                margin-right: auto;
                &:after {
                    display: none !important;
                }
            }
            &:after {
                content: '';
                display: block;
                background-image: url('/Content/images/ads_word_black.svg');
                background-repeat: no-repeat;
                background-size: contain;
                width: 34px;
                height: 18px;
                margin-left: auto;
                margin-right: auto;
                position: absolute;
                bottom: 5px;
                left: calc(50% - 17px);
            }
        }
    }
    // &.home-page {
    //     .city-writers-section {
    //         >.ad-gpt-pro {
    //             height: 1px;
    //             margin-top: 10px;
    //             margin-bottom: 30px;
    //         }
    //     }
    //     #div-gpt-ad-leaderboard-1 {
    //         margin-top: 0;
    //         margin-bottom: 0;
    //         div[id*='google_ads_iframe'] {
    //             position: relative;
    //             margin-left: auto;
    //             margin-right: auto;
    //             height: 130px;
    //             background-color: #f9f9fa;
    //             padding-top: 15px;
    //             padding-bottom: 25px;
    //             width: 1020px;
    //             @media(max-width: 1024px) {
    //                 width: 100%;
    //                 max-width: 100%;
    //             }
    //             @media (max-width: 1023px) {
    //                 height: 169px;
    //             }
    //         }
    //     }
    //     div[id*='div-gpt-ad-billboard'] {
    //         position: relative;
    //         div[id*='google_ads_iframe'] {
    //             margin-left: auto;
    //             margin-right: auto;
    //             height: 290px;
    //             background-color: #f9f9fa;
    //             padding-top: 15px;
    //             padding-bottom: 25px;
    //             width: 1020px;
    //             @media(max-width: 1024px) {
    //                 width: 100%;
    //                 max-width: 100%;
    //             }
    //             @media (max-width: 1023px) {
    //                 height: 640px;
    //             }
    //         }
    //     }
    // }
}

