/********** Start City Writers **********/

.city-writers-section {
    margin-bottom: 20px;
    margin-top: 0;
    @media ($mobile) {
        margin-bottom: 0;
        .row {
            margin-left: 0;
            margin-right: 0;
            .col {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    .main-block {
        .row {
            .col-12 {
                @media ($mobile) {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }
        .right-side {
            @media ($mobile) {
                margin-bottom: 20px;
            }
            .image-and-play {
                position: relative;
                a {
                    display: block;
                    margin-bottom: 15px;
                    //width: 705px;
                    //height: 417px;
                    @media ($mobile) {
                        width: 100%;
                        //height: 190px;
                        margin-bottom: 10px;
                    }
                }
            }
            .author-and-section {
                margin-bottom: 5px;
                @media ($mobile) {
                    margin-bottom: 10px;
                }
                p.section {
                    a {
                        font-size: 14px;
                        font-weight: bold;
                        color: $blue2;
                        @media ($mobile) {
                            font-size: 12px;
                        }
                    }
                }
            }
            .title {
                margin-bottom: 10px;
                a {
                    font-size: 24px;
                    font-weight: bold;
                    line-height: 1.67;
                    color: $gray1;
                    @media ($mobile) {
                        font-size: 18px;
                        line-height: 1.56;
                    }
                }
            }
            .description {
                font-size: 16px;
                line-height: 1.56;
                color: $gray3;
                @media ($mobile) {
                    font-size: 14px;
                    line-height: 1.71;
                }
            }
        }
        .left-side {
            @media ($mobile) {
                display: flex;
            }
            .image-and-play {
                position: relative;
                display: block;
                //width: 338px;
                //height: 190px;
                @media ($mobile) {
                    flex: 0 0 120px;
                    max-width: 120px;
                    margin-left: 15px;
                    width: 120px;
                    height: 75px;
                }
                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
            &:first-child {
                margin-bottom: 17px;
                @media ($mobile) {
                    margin-bottom: 20px;
                }
            }
            .content-slide {
                margin-top: 10px;
                @media ($mobile) {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
                .title {
                    margin-bottom: 10px;
                    line-height: 0;
                    a {
                        font-size: 18px;
                        font-weight: bold;
                        line-height: 1.44;
                        color: $gray1;
                        @media ($mobile) {
                            font-size: 14px;
                            line-height: 1.4;
                        }
                    }
                }
                .author-and-section {
                    p.section {
                        a {
                            font-size: 12px;
                            font-weight: bold;
                            color: $blue2;
                        }
                    }
                }
            }
        }
    }
    .city-writers {
        border: solid 1px $gray5;
        padding: 20px 20px 15px 20px;
        @media ($mobile) {
            height: auto;
            border: none;
            padding: 0;
            padding-left: 0;
            padding-right: 0;
            margin-top: 30px;
            margin-bottom: 30px;
        }
        .writers {
            ul {
                @media ($mobile) {
                    width: 100%;
                }
            }
            li {
                &:not(:last-child) {
                    border-bottom: solid 1px $gray7;
                    .block-content {
                        padding-bottom: 14px;
                    }
                }
                .block-content {
                    padding-top: 14px;
                    display: flex;
                    align-items: center;
                    .right-side {
                        width: 70px;
                        height: 70px;
                        flex: 0 0 70px;
                        max-width: 70px;
                        img {
                            border-radius: 50%;
                        }
                    }
                    .left-side {
                        padding-right: 15px;
                        .text {
                            font-size: 14px;
                            font-weight: bold;
                            line-height: 1.3;
                            color: $gray1;
                            margin-bottom: 5px;
                            display: flex;
                            align-items: center;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            /* number of lines to show */
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                            @media ($mobile) {
                                max-height: 56px;
                                line-height: 1.56;
                                align-items: baseline;
                            }
                        }
                        .writer-name {
                            font-size: 12px;
                            font-weight: bold;
                            line-height: 1.67;
                            color: $blue2;
                        }
                    }
                }
            }
        }
    }
}

.last-news-section {
    @media ($mobile) {
        margin-bottom: 10px;
    }
    .title {
        margin-bottom: 30px;
    }
    .right-side {
        margin-bottom: 30px;
        @media ($mobile) {
            margin-bottom: 20px;
        }
        .image-and-play {
            position: relative;
            a {
                display: block;
                margin-bottom: 10px;
                //width: 338px;
                @media ($mobile) {
                    width: 100%;
                }
            }
        }
        .title {
            margin-bottom: 10px;
            a {
                font-size: 18px;
                font-weight: bold;
                line-height: 1.44;
                color: $gray1;
            }
        }
        .author-and-section {
            p.section {
                a {
                    font-size: 12px;
                    font-weight: bold;
                    color: $blue2;
                }
            }
        }
    }
    .left-side {
        @media ($mobile) {
            display: flex;
        }
        .image-and-play {
            position: relative;
            display: block;
            //width: 338px;
            //height: 190px;
            @media ($mobile) {
                flex: 0 0 120px;
                max-width: 120px;
                margin-left: 15px;
                width: 120px;
                height: 75px;
            }
            a {
                display: block;
                margin-bottom: 10px;
                width: 100%;
                height: 100%;
            }
        }
        &:first-child {
            margin-bottom: 17px;
            @media ($mobile) {
                margin-bottom: 20px;
            }
        }
        .content-slide {
            @media ($mobile) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            .title {
                margin-bottom: 10px;
                line-height: 0;
                a {
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 1.44;
                    color: $gray1;
                    @media ($mobile) {
                        font-size: 14px;
                        line-height: 1.4;
                    }
                }
            }
            .author-and-section {
                p.section {
                    a {
                        font-size: 12px;
                        font-weight: bold;
                        color: $blue2;
                    }
                }
            }
        }
    }
}

.summary-pages.city-writers-section {
    margin-top: 0;
    .city-writers {
        @media ($mobile) {
            margin-top: 30px;
        }
    }
}


/********** End City Writers **********/


/********** Start lebanon most viewed- section **********/

.lebanon-most-viewed-section {
    margin-bottom: 35px;
    margin-top: 13px;
    @media ($mobile) {
        margin-bottom: 30px;
        margin-top: 0;
    }
    .lebanon-section {
        @media ($mobile) {
            margin-bottom: 30px;
        }
        .title {
            margin-bottom: 30px;
            @media ($mobile) {
                margin-bottom: 21px;
            }
        }
        a.title.right.text {
            display: block;
            margin-bottom: 15px;
            @media ($mobile) {
                margin-bottom: 10px;
            }
        }
    }
    .one-large-four-small {
        .four-small {
            margin-bottom: 10px;
            .col {
                margin-bottom: 20px;
            }
        }
    }
}

.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
    float: none;
    padding: 0;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
    color: inherit;
}

.ui-widget {
    font-family: inherit;
    font-size: inherit;
}

.ui-tabs .ui-tabs-nav {
    padding: 0;
}

.ui-tabs {
    position: initial;
    padding: 0;
}

.ui-widget.ui-widget-content {
    background-color: $white;
}

.ui-widget-content {
    background: transparent;
    color: initial;
}

.ui-corner-all {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
    border-top-right-radius: 0;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
    border-top-left-radius: 0;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
    border: none;
    background: transparent;
    font-weight: inherit;
    color: inherit;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
    border: none;
    background: transparent;
    font-weight: inherit;
    color: inherit;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active {
    margin-bottom: 0;
    padding-bottom: 0;
}

.ui-widget-header {
    border: none;
    background: transparent;
    color: initial;
    font-weight: initial;
}

.ui-tabs .ui-tabs-nav li {
    list-style: none;
    float: none;
    position: initial;
    top: auto;
    margin: 0;
    border-bottom-width: 0;
    padding: 0;
    white-space: nowrap;
}

.ui-tabs .ui-tabs-panel {
    padding: 0;
}

#MostReadItems {
    margin-bottom: 30px;
}

#MostReadAndSentItems,
#MostReadItems {
    #tabs_most_read {
        border: 0 !important;
        .ui-tabs-nav {
            border-bottom: 1px solid $gray6;
            display: flex;
            margin-bottom: 36px;
            @media ($mobile) {
                margin-bottom: 30px;
            }
            .tabs_style {
                flex: 0 0 50%;
                max-width: 50%;
                a.ui-tabs-anchor {
                    font-size: 22px;
                    text-align: center;
                    font-weight: bold;
                    color: $gray3;
                    display: block;
                    padding-bottom: 20px;
                    pointer-events: none;
                    @media ($mobile) {
                        font-size: 18px;
                        padding-bottom: 13px;
                    }
                }
                &.ui-tabs-active {
                    a {
                        cursor: pointer;
                        color: $gray1;
                        border-bottom: 6px solid $blue2;
                        @media ($mobile) {
                            border-bottom: 4px solid $blue2;
                        }
                    }
                }
            }
        }
    }
    .tabs_ads_list {
        li {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
            .block {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-right: -7.5px;
                margin-left: -7.5px;
                a:first-child {
                    height: 105px;
                    flex: 0 0 38.625%;
                    max-width: 38.625%;
                    padding-right: 7.5px;
                    padding-left: 7.5px;
                    @media ($mobile) {
                        flex: 0 0 34.33%;
                        max-width: 34.33%;
                    }
                    @media (min-width: 768px) and ($mobile) {
                        height: auto;
                    }
                    @media (max-width: 767px) {
                        height: 65px;
                    }
                    img {
                        border: solid 0.5px $gray7;
                    }
                }
                a.title {
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 1.7;
                    color: $gray1;
                    @media ($mobile) {
                        font-size: 14px;
                        line-height: 1.57;
                    }
                    flex: 0 0 61.375%;
                    max-width: 61.375%;
                    padding-right: 7.5px;
                    padding-left: 7.5px;
                    @media ($mobile) {
                        flex: 0 0 65.67%;
                        max-width: 65.67%;
                    }
                    h2 {
                        max-height: 65px;
                        overflow: hidden;
                        a {
                            font-size: 20px;
                            font-weight: bold;
                            line-height: 1.7;
                            color: $gray1;
                            @media ($mobile) {
                                font-size: 14px;
                                line-height: 1.57;
                            }
                        }
                    }
                }
            }
        }
    }
}


/********** End lebanon most viewed- section **********/


/********** Start economie and world section **********/

.economie-and-world-section {
    margin-top: 45px;
    margin-bottom: 35px;
    @media ($mobile) {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .economie-section {
        .title {
            margin-bottom: 30px;
        }
        .row {
            margin-right: -15px;
            margin-left: -15px;
            .col {
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }
    .world-section {
        margin-top: 40px;
        @media ($mobile) {
            margin-top: 30px;
            margin-bottom: 30px;
        }
        .title {
            margin-bottom: 30px;
        }
        .row {
            margin-right: -15px;
            margin-left: -15px;
            .col {
                padding-right: 15px;
                padding-left: 15px;
            }
        }
        .right-img-left-desc {
            margin-bottom: 30px;
            @media ($mobile) {
                margin-bottom: 20px;
            }
        }
    }
    .caricature {
        padding: 20px 30px 30px 29px;
        border: solid 1px $gray5;
        margin-bottom: 45px;
        @media ($mobile) {
            padding: 0;
            border: none;
            margin-bottom: 30px;
        }
        .title {
            margin-bottom: 25px;
            @media ($mobile) {
                margin-bottom: 21px;
            }
        }
        .content-caricature {
            .top-section {
                display: flex;
                align-items: center;
                margin-bottom: 13px;
                @media ($mobile) {
                    margin-bottom: 20px;
                }
                .edit-icon {
                    display: block;
                    width: 20px;
                    height: 20px;
                    margin-left: 10px;
                    img {
                        object-fit: contain;
                    }
                }
                .author {
                    font-size: 16px;
                    font-weight: bold;
                    color: $gray1;
                }
            }
            .middle-section {
                height: 235px;
                margin-bottom: 20px;
                @media (min-width: 768px) and (max-width: 1024px) {
                    height: auto;
                }
                @media (max-width:767px) {
                    height: auto;
                }
                img {
                    border: solid 0.5px $gray7;
                }
            }
        }
    }
    .blogs-sction {
        .title {
            margin-bottom: 20px;
            @media ($mobile) {
                margin-bottom: 20px;
            }
        }
        ul {
            li {
                &:not(:last-child) {
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                    border-bottom: 1px solid $gray6;
                    border-width: calc(100% - 18px);
                    @media ($mobile) {
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
                .right-img-left-desc {
                    @media ($mobile) {
                        margin-right: 0;
                        margin-left: 0;
                    }
                }
                .right-side {
                    width: 90px;
                    height: 90px;
                    flex: 0 0 90px;
                    border-radius: 50%;
                    overflow: hidden;
                    padding-right: 0;
                    padding-left: 0;
                    @media ($mobile) {
                        width: 90px;
                        height: 90px;
                        flex: 0 0 90px;
                        max-width: 90px;
                        padding-right: 0;
                        padding-left: 0;
                        margin-bottom: 0;
                    }
                }
                .left-side {
                    padding-right: 20px;
                    @media ($mobile) {
                        height: auto;
                        padding-right: 15px;
                        padding-left: 0;
                        margin-bottom: 0;
                        justify-content: center;
                    }
                    h2 {
                        padding-top: 0;
                        margin-bottom: 10px;
                        @media ($mobile) {
                            margin-bottom: 5px;
                        }
                        a {
                            height: 56px;
                            font-size: 20px;
                            font-weight: bold;
                            line-height: 1.4;
                            color: $gray1;
                            @media ($mobile) {
                                font-size: 18px;
                                line-height: 1.4;
                                height: 56px;
                            }
                        }
                    }
                    .author {
                        a {
                            font-size: 14px;
                            color: $blue2;
                            line-height: 1.43;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}


/********** End economie and world section **********/


/********** Start stations culture media **********/

.stations-culture-media {
    margin-top: 40px;
    margin-bottom: 40px;
    @media ($mobile) {
        margin-top: 0;
        margin-bottom: 30px;
    }
    .title {
        margin-bottom: 42px;
        @media ($mobile) {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
    .large-block-vertical {
        margin-bottom: 39px;
        @media ($mobile) {
            margin-bottom: 30px;
        }
        .details {
            h2 {
                height: 78px;
                overflow: hidden;
                @media ($mobile) {
                    height: auto;
                }
                a {
                    font-size: 20px;
                    line-height: 1.4;
                    @media ($mobile) {
                        font-size: 18px;
                        line-height: 1.78;
                    }
                }
            }
        }
        .details .description {
            height: 56px;
            overflow: hidden;
            @media ($mobile) {
                height: auto;
                max-height: 65px;
            }
        }
    }
    .right-img-left-desc {
        margin-bottom: 30px;
    }
}


/********** End stations culture media **********/


/********** Start private files **********/

.private-files {
    margin-top: 40px;
    @media ($mobile) {
        margin-top: 30px;
    }
    .title.with-line {
        margin-bottom: 42px;
        @media ($mobile) {
            margin-bottom: 30px;
        }
    }
    .doi-home {
        height: 260px;
        @media (min-width: 768px) and (max-width: 1024px) {
            height: auto;
        }
        @media (max-width:767px) {
            height: auto;
        }
        @media ($mobile) {
            margin-bottom: 20px;
        }
    }
    #carouselExampleInterval2 {
        height: 260px;
        @media (min-width: 768px) and (max-width: 1024px) {
            height: auto;
            margin-bottom: 20px;
        }
        @media (max-width:767px) {
            height: 220px;
            margin-bottom: 20px;
        }
        .carousel-inner .carousel-item-slick {
            .content-slide {
                padding-right: 0;
                padding-bottom: 0;
                padding-left: 0;
                width: 100%;
                background-color: rgba(5, 43, 91, 0.85);
                @media (min-width: 768px) and (max-width: 1024px) {
                    top: 365px;
                }
                @media (max-width:767px) {
                    top: 180px;
                }
                @media ($mobile) {
                    padding: 0;
                }
                .title {
                    a {
                        display: block;
                        font-size: 16px;
                        font-weight: bold;
                        padding: 8px 11px 12px;
                        margin-bottom: 0;
                        @media ($mobile) {
                            font-size: 14px;
                            line-height: normal;
                            max-height: 34px;
                            overflow: hidden;
                        }
                    }
                }
            }
            &:before {
                display: none;
            }
        }
        .slick-prev,
        .slick-next {
            top: calc(50% - 16px);
            left: 20px;
            @media ($mobile) {
                width: 32px;
                height: 32px;
            }
            &.slick-prev {
                left: auto;
                right: 20px;
            }
        }
    }
    .youtibe-home {
        iframe {
            width: 100% !important;
            height: 260px !important;
            @media (min-width: 992px) and (max-width: 1024px) {
                height: 170px!important;
            }
            @media (min-width: 768px) and (max-width: 991px) {
                height: 423px!important;
            }
            @media (max-width:767px) {
                height: 180px!important;
            }
        }
    }
}

#carouselExampleInterval3 {
    /*.carousel-control-prev,
    .carousel-control-next {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 89%;
        left: 30px;
        right: auto;
        background-color: $white;
        opacity: 1;
        @media ($mobile) {
            width: 24px;
            height: 24px;
            left: 20px;
        }
        &:hover {
            background-color: $yellow1;
            .carousel-control-prev-icon {
                transform: scaleX(1);
                background-image: url('/Content/images/icons/arrow-white.svg');
            }
            .carousel-control-next-icon {
                transform: scaleX(-1);
                background-image: url('/Content/images/icons/arrow-white.svg');
            }
        }
        &.carousel-control-next {
            left: 77px;
            @media ($mobile) {
                left: 54px;
            }
        }
    }
    .carousel-control-next-icon {
        width: 16px;
        height: 16px;
        background-image: url('/Content/images/icons/arrow-blue.svg');
        @media ($mobile) {
            width: 12px;
            height: 12px;
        }
    }
    .carousel-control-prev-icon {
        width: 16px;
        height: 16px;
        transform: scaleX(-1);
        background-image: url('/Content/images/icons/arrow-blue.svg');
        @media ($mobile) {
            width: 12px;
            height: 12px;
        }
    }
    .carousel-control-prev,
    .carousel-control-next {
        width: 32px;
        height: 32px;
        position: absolute;
        background-color: #fff;
        opacity: 1;
        top: calc(50% - 9px);
        left: 20px;
        @media ($mobile) {
            width: 32px;
            height: 32px;
        }
        &.carousel-control-next {
            left: auto;
            right: 20px;
        }
    }*/
    .slick-prev,
    .slick-next {
        width: 32px;
        height: 32px;
        z-index: 3;
        position: absolute;
        font-size: 0;
        border: 0;
        top: calc(50% - 9px);
        left: 20px;
        right: auto;
        background-color: $white;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            transform: scaleX(-1);
            background-image: url('/Content/images/icons/arrow-blue.svg');
            background-size: contain;
            @media (max-width: 1024px) {
                width: 12px;
                height: 12px;
            }
        }
        &:hover {
            background-color: $yellow1;
            &::before {
                transform: scaleX(1);
                background-image: url('/Content/images/icons/arrow-white.svg');
            }
            &.slick-prev {
                &::before {
                    transform: scaleX(-1);
                    background-image: url('/Content/images/icons/arrow-white.svg');
                }
            }
        }
        &.slick-prev {
            left: auto;
            right: 20px;
            @media (max-width: 1024px) {
                right: 20px;
            }
            &::before {
                transform: scaleX(1);
                background-image: url('/Content/images/icons/arrow-blue.svg');
            }
        }
    }
}


/********** End private files **********/