.newslist-page {
    margin-bottom: 30px;
    .section-side-bar {
        @media ($mobile) {
            margin-top: 30px;
        }
    }
    .main-title {
        font-size: 40px;
        font-weight: bold;
        line-height: 0.75;
        color: $gray1;
        margin-bottom: 30px;
        @media ($mobile) {
            font-size: 24px;
            line-height: 1.83;
            margin-bottom: 25px;
        }
    }
    ul.newslist {
        li {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px dashed $gray5;
            &:last-child {
                margin-bottom: 40px;
            }
            @media ($mobile) {
                margin-bottom: 24px;
            }
            .news-title {
                display: block;
                font-size: 24px;
                font-weight: bold;
                line-height: 1.42;
                color: $gray1;
                margin-bottom: 10px;
                @media ($mobile) {
                    font-size: 18px;
                    line-height: 1.56;
                    margin-bottom: 12px;
                }
            }
            .date-and-author {
                display: flex;
                align-items: center;
                a {
                    font-size: 14px;
                    color: $gray4;
                    display: block;
                    padding-right: 10px;
                    margin-right: 10px;
                    border-right: 1px solid $gray4;
                    @media ($mobile) {
                        font-size: 12px;
                    }
                }
                .date {
                    font-size: 14px;
                    color: $gray4;
                    @media ($mobile) {
                        font-size: 12px;
                    }
                }
            }
            .descrption {
                margin-top: 5px;
                font-size: 16px;
                color: $gray4;
                line-height: 1.4;
                margin-top: 10px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .social-media {
        margin-top: 0;
    }
}