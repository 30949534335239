.site-map {
    margin-bottom: 113px;
    @media ($mobile) {
        margin-bottom: 44px;
    }
    .static-title {
        margin-bottom: 40px;
        @media ($mobile) {
            margin-bottom: 37px;
        }
    }
    .map-setion {
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 145px;
            height: 50px;
            position: relative;
            background-color: $blue3;
            font-size: 18px;
            font-weight: bold;
            color: $blue2;
            border: solid 1px $blue2;
        }
        .main-page {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
            padding-bottom: 41px;
            border-bottom: 2px solid $gray4;
            margin-left: 72px;
            margin-right: 72px;
            @media ($mobile) {
                border-bottom: 0;
                margin-left: 0;
                margin-right: 0;
            }
            a {
                &::before {
                    content: '';
                    width: 2px;
                    height: 42px;
                    background-color: $gray4;
                    position: absolute;
                    bottom: -42px;
                    left: calc(50% - 1px);
                }
                @media ($mobile) {
                    &::after {
                        content: '';
                        width: 104px;
                        height: 2px;
                        background-color: $gray4;
                        position: absolute;
                        bottom: -42px;
                        left: calc(50% - 1px);
                    }
                }
            }
        }
        .section-pages {
            display: flex;
            justify-content: space-between;
            @media ($mobile) {
                flex-wrap: wrap;
                align-items: center;
                flex-direction: column;
            }
            >li {
                @media ($mobile) {
                    position: relative;
                    &::before {
                        content: '';
                        width: 2px;
                        height: 68px;
                        background-color: $gray4;
                        position: absolute;
                        top: -42px;
                        right: -30px;
                    }
                }
                &:not(:first-child) {
                    @media ($mobile) {
                        margin-top: 15px;
                    }
                }
                &:last-child {
                    >a {
                        &::after {
                            content: '';
                            width: 2px;
                            height: 23px;
                            background-color: $gray4;
                            position: absolute;
                            bottom: -23px;
                            left: calc(50% - 1px);
                        }
                    }
                }
                >a {
                    &::before {
                        content: '';
                        width: 2px;
                        height: 43px;
                        background-color: $gray4;
                        position: absolute;
                        top: -43px;
                        left: calc(50% - 1px);
                        @media ($mobile) {
                            width: 30px;
                            height: 2px;
                            background-color: $gray4;
                            position: absolute;
                            top: calc(50% - 1px);
                            right: -30px;
                        }
                    }
                }
            }
        }
        .static-pages {
            margin-top: 43px;
            position: relative;
            &::before {
                content: '';
                width: 2px;
                height: 100%;
                background-color: $gray4;
                position: absolute;
                right: 0;
                top: -22px;
            }
            &::after {
                content: '';
                width: 73px;
                height: 2px;
                background-color: $gray4;
                position: absolute;
                right: 0;
                top: -22px;
            }
            li {
                margin-bottom: 16px;
                display: flex;
                justify-content: end;
                >a {
                    width: 120px;
                    height: 45px;
                    background-color: $gray7;
                    border: 1px solid $gray4;
                    font-size: 16px;
                    color: $gray2;
                    &::before {
                        content: '';
                        width: 26px;
                        height: 2px;
                        background-color: $gray4;
                        position: absolute;
                        right: -26px;
                        top: calc(50% - 1px);
                    }
                }
            }
        }
    }
}