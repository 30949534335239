.caricatures {
    .pagination {
        margin-top: 30px;
        margin-bottom: 30px;
        @media ($mobile) {
            margin-top: 10px;
        }
    }
    .title-and-filter {
        margin-bottom: 40px;
        @media ($mobile) {
            margin-bottom: 30px;
        }
        .caricatures-title {
            font-size: 40px;
            font-weight: bold;
            line-height: 0.75;
            color: $gray1;
            @media ($mobile) {
                font-size: 24px;
                line-height: 1.5;
                margin-bottom: 20px;
            }
        }
    }
}